import {
  Column,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryColumn,
  RelationId,
  UpdateDateColumn,
} from 'typeorm';

import { Sports, ISports, Tournaments, ITournaments } from './';
import { GenderEnum } from '../types';

export type ITournamentTemplate = TournamentTemplates;

@Entity('tournament_templates')
export class TournamentTemplates {
  @PrimaryColumn()
  public id!: number;

  @Column()
  public name!: string;

  @ManyToOne(() => Sports, (item) => item.id)
  @JoinColumn({
    name: 'sportFK',
  })
  public sport!: ISports;

  @RelationId((item: TournamentTemplates) => item.sport)
  @Column({
    name: 'sportFK',
  })
  public sportFK!: number;

  @Column({
    type: 'enum',
    enum: GenderEnum,
    nullable: false,
  })
  public gender!: GenderEnum;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;

  @OneToMany(() => Tournaments, (item) => item.tournamentTemplate)
  public tournaments!: ITournaments[];
}
