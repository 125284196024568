import { CanMatchFn, Route, UrlSegment } from '@angular/router';
import { inject } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';

import { Observable, tap } from 'rxjs';

export const isAuth: CanMatchFn = <T extends {}>(
  route: Route,
  segments: UrlSegment[]
): Observable<boolean> => {
  const authService = inject<AuthService<T>>(AuthService);
  return authService.isAuthenticated$.pipe(
    tap((isAuth) => {
      if (!isAuth) {
        authService.loginWithRedirect();
      }
    })
  );
};
