import { InjectionToken } from '@angular/core';
import { EventIncidentTypePoint } from '@matchman/database/entities';
import { Subject } from 'rxjs';

import { IncidentListComponent } from '../incident-list.component';

export const saveIncidentPointEffect = new InjectionToken<
  Subject<EventIncidentTypePoint>
>('saveIncidentPointEffect', {
  providedIn: IncidentListComponent,
  factory: () => new Subject(),
});
