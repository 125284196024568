import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObserveFunc } from '@matchman/common-front';
import { Events, EventsLineUp } from '@matchman/database/entities';
import { endWithAsyncPipe } from '@matchman/frontend-utils';
import { setProp } from '@ngneat/elf';
import { setEntities } from '@ngneat/elf-entities';
import { map, switchMap } from 'rxjs/operators';
import { FilterOperand } from 'json-api-nestjs/filter-operand';
import { QueryField, QueryParams } from 'json-api-nestjs';
import { JsonApiSdkService } from 'json-api-nestjs-sdk';
import {
  shareReplay,
  combineLatest,
  distinctUntilChanged,
  startWith,
  catchError,
  of,
} from 'rxjs';
import { Entries } from 'type-fest';

import { StateService } from '../../service/state.service';
import { EventListState, eventsEntitiesRef } from '../index';
import { getEventFilter } from '../../../../utils';

import { SearchFormValue } from '../../../event-search-form/service/event-search-form.service';

export function loadEvents(this: StateService): ObserveFunc<EventListState> {
  const activatedRoute = inject(ActivatedRoute);
  const jsonApiUtilsService = inject(JsonApiSdkService);

  const loadEvents = (params: QueryParams<Events>) => {
    return jsonApiUtilsService.getAll(Events, params).pipe(
      map((r) => {
        const events = r.map((i) => {
          i.id = parseInt(`${i.id}`, 10);
          return i;
        });

        return this.reduceForUpdate(
          setEntities(events, { ref: eventsEntitiesRef }),
          setProp('pageInfo', {
            page: r.pageNumber,
            countAll: r.totalItems,
            pageSize: r.pageSize,
          })
        );
      }),
      startWith(
        this.reduceForUpdate(
          setProp('statusEvents', (state) => ({
            ...state,
            ...{
              isLoading: true,
              status: 'idle' as typeof state['status'],
            },
          }))
        )
      ),
      endWithAsyncPipe(
        this.reduceForUpdate(
          setProp('statusEvents', (state) => ({
            ...state,
            ...{
              isLoading: false,
              isSuccess: true,
              status: 'done' as typeof state['status'],
            },
          }))
        )
      ),
      catchError((err) => {
        return of(
          this.reduceForUpdate(
            setProp('statusEvents', (state) => ({
              ...state,
              ...{
                isLoading: false,
                isSuccess: false,
                isError: true,
                status: 'done' as typeof state['status'],
                error: err,
              },
            }))
          )
        );
      })
    );
  };

  const filter$ = activatedRoute.queryParams.pipe(
    getEventFilter(),
    distinctUntilChanged(
      (pre, current) => JSON.stringify(pre) === JSON.stringify(current)
    ),
    shareReplay({ refCount: true })
  );

  const page$ = activatedRoute.queryParams.pipe(
    map((params) => params['page'] || '1'),
    map((pageStr) => parseInt(pageStr)),
    distinctUntilChanged(),
    shareReplay({ refCount: true })
  );

  return combineLatest([filter$, page$]).pipe(
    map(([filter, page]) => {
      const params = {
        page: {
          number: page,
          size: 100,
        },
        include: [
          'awayTeam',
          'homeTeam',
          'status',
          'tournament',
          'tournamentStage',
        ],
        sort: {
          target: {
            startDate: 'DESC',
          },
        },
      } as QueryParams<Events>;

      const filterTarget = (
        Object.entries(filter) as unknown as Entries<SearchFormValue>
      ).reduce<QueryParams<Events>['filter']['target']>((acum, item) => {
        const [key, value] = item;
        if (value === null) {
          return acum;
        }
        const conditional = {
          [FilterOperand.eq]: `${value}`,
        };

        switch (key) {
          case 'tournament':
            acum['tournamentFK'] = conditional;
            break;
          case 'awayTeam':
            acum['awayTeamId'] = conditional;
            break;
          case 'homeTeam':
            acum['homeTeamId'] = conditional;
            break;
        }
        if (key === 'eventDate' && Array.isArray(value) && value.length > 0) {
          acum['startDate'] = {
            gte: value[0].toISOString(),
            lte: value[1].toISOString(),
          };
        }
        if (key === 'showDemo' && value === false) {
          acum['id'] = {
            gte: '0',
          };
        }
        return acum;
      }, {});
      params.filter = {
        target: filterTarget,
        relation: {},
      };

      return params;
    }),
    switchMap((params) => loadEvents(params))
  );
}
