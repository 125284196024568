import {
  Column,
  Entity,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  RelationId,
} from 'typeorm';
import { Contest, IContest } from './contest';
import { JoinUserContest, IJoinUserContest } from './join-user-contest';
import { IPlayers, Players } from './players';

export type IPlayerUserContest = PlayerUserContest;

@Entity('player_user_contest')
export class PlayerUserContest {
  @PrimaryGeneratedColumn()
  public id!: number;

  @RelationId((item: PlayerUserContest) => item.contest)
  @Column({
    name: 'contest_id',
  })
  public contestId!: number;

  @ManyToOne(() => Contest, (item) => item.id)
  @JoinColumn({
    name: 'contest_id',
    referencedColumnName: 'id',
  })
  public contest!: IContest;

  @RelationId((item: PlayerUserContest) => item.joinUserContest)
  @Column({
    name: 'join_user_contest_id',
  })
  public joinUserContestId!: number;

  @ManyToOne(() => JoinUserContest, (item) => item.id)
  @JoinColumn({
    name: 'join_user_contest_id',
    referencedColumnName: 'id',
  })
  public joinUserContest!: IJoinUserContest;

  @RelationId((item: PlayerUserContest) => item.player)
  @Column({
    name: 'player_id',
  })
  public playerId!: number;

  @ManyToOne(() => Players, (item) => item.id)
  @JoinColumn({
    name: 'player_id',
    referencedColumnName: 'id',
  })
  public player!: IPlayers;
}
