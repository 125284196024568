import { inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { setProp } from '@ngneat/elf';
import { upsertEntities } from '@ngneat/elf-entities';
import { ObserveFunc } from '@matchman/common-front';
import { BillingAccount } from '@matchman/database/entities';
import { endWithAsyncPipe } from '@matchman/frontend-utils';
import { map, switchMap } from 'rxjs/operators';
import { JsonApiSdkService } from 'json-api-nestjs-sdk';
import { catchError, of, startWith, tap } from 'rxjs';

import { StateService } from '../../service/state.service';
import { saveAccountEffect } from '../../effect';
import { BillingAccountListState, accountListEntitiesRef } from '../index';

export function saveAccount(
  this: StateService
): ObserveFunc<BillingAccountListState> {
  const saveAccount = inject(saveAccountEffect);
  const jsonApiUtilsService = inject(JsonApiSdkService);
  const router = inject(Router);

  const saveAccount$ = (data: BillingAccount, type: 'patchOne' | 'postOne') =>
    jsonApiUtilsService[type](data).pipe(
      map((r) =>
        this.reduceForUpdate(upsertEntities(r, { ref: accountListEntitiesRef }))
      ),
      tap(() => router.navigate(['billing', { outlets: { modal: null } }])),
      startWith(this.reduceForUpdate(setProp('savingInModal', true))),
      endWithAsyncPipe(this.reduceForUpdate(setProp('savingInModal', false))),
      catchError((err) =>
        of(this.reduceForUpdate(setProp('savingInModal', false)))
      )
    );

  return saveAccount.asObservable().pipe(
    map((r) => {
      const account = new BillingAccount();
      const type = r.id ? 'patchOne' : 'postOne';
      account.meta = r.meta;
      account.details = r.details;
      account.amountType = r.amountType;
      if (r.id) {
        account.id = r.id;
      }
      return [account, type] as [BillingAccount, 'patchOne' | 'postOne'];
    }),
    switchMap(([data, type]) => saveAccount$(data, type))
  );
}
