<app-event-search-form (valueChanges)="setParamToRouter($event)" [setSearchValue]="searchFilter$ | async"/>
<nz-table
  *rxLet="vm$; let vm"
  appResizeTable
  [nzBordered]="true"
  [nzData]="vm.eventsItem"
  [nzLoading]="vm.loadStatus.isLoading"
  [nzTotal]="vm.pageInfo.countAll"
  [nzPageSize]="vm.pageInfo.pageSize"
  [nzPageIndex]="vm.pageInfo.page"
  [nzFrontPagination]="false"
  (nzQueryParams)="onQueryParamsChange($event)"
  nzSize="small"
>
  <thead>
  <tr>
    <th [nzWidth]="'300px'">Home Team</th>
    <th [nzWidth]="'50px'">Score</th>
    <th [nzWidth]="'300px'">Away Team</th>
    <th [nzWidth]="'200px'">Status Name(Status Type)</th>
    <th>Tournament</th>
    <th [nzWidth]="'100px'">Date Event</th>
    <th [nzWidth]="'100px'"></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let event of vm.eventsItem; trackBy: trackBy">
    <td>{{ event.homeTeam.name }}</td>
    <td>{{ event.homeTeamScore }} - {{ event.awayTeamScore }}</td>
    <td>{{ event.awayTeam.name }}</td>
    <td>{{ event.status.name }}({{ event.status.statusType }})</td>
    <td>{{ event.tournamentStage.name }} - {{ event.tournament.name }}</td>
    <td>{{ event.startDate | date }}</td>
    <td>
      <a [routerLink]="['./', event.id, 'line-up']">Line Up</a>
    </td>
  </tr>
  </tbody>
</nz-table>
