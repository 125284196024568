import {
  Column,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  RelationId,
  UpdateDateColumn,
} from 'typeorm';

import {
  Events,
  IEvents,
  Teams,
  ITeams,
  Players,
  IPlayers,
  PositionsType,
  IPositionsType,
  IncidentHasPoint,
  IIncidentHasPoint,
  PlayersEmaHistory,
  IPlayersEmaHistory,
} from './';
import { PositionEnum, PositionTypeEnum, SubstitutionEnum } from '../types';

export type IEventsLineUp = EventsLineUp;

@Entity('events_line_up')
export class EventsLineUp {
  @PrimaryGeneratedColumn()
  public id!: number;

  @Column({
    name: 'is_captain',
  })
  public isCaptain!: boolean;

  @ManyToOne(() => Events, (item) => item.lineUp)
  @JoinColumn({
    name: 'event_id',
    referencedColumnName: 'id',
  })
  public events!: IEvents;

  @RelationId((item: EventsLineUp) => item.events)
  @Column({
    name: 'event_id',
  })
  public eventId!: number;

  @ManyToOne(() => Teams, (item) => item.lineUp)
  @JoinColumn({
    name: 'team_id',
    referencedColumnName: 'id',
  })
  public teams!: ITeams;

  @RelationId((item: EventsLineUp) => item.teams)
  @Column({
    name: 'team_id',
  })
  public teamId!: number;

  @ManyToOne(() => Players, (item) => item.lineUp)
  @JoinColumn({
    name: 'player_id',
    referencedColumnName: 'id',
  })
  public players!: IPlayers;

  @RelationId((item: EventsLineUp) => item.players)
  @Column({
    name: 'player_id',
  })
  public playerId!: number;

  @Column({
    name: 'shirt_number',
  })
  public shirtNumber!: number;

  @Column({
    name: 'pos_number',
  })
  public posNumber!: number;

  @ManyToOne(() => PositionsType, (item) => item.name)
  @JoinColumn({
    name: 'position_name',
    referencedColumnName: 'name',
  })
  public position!: IPositionsType;

  @RelationId((item: EventsLineUp) => item.position)
  @Column({
    type: 'enum',
    enum: PositionTypeEnum,
    nullable: false,
    name: 'position_name',
  })
  public positionName!: PositionEnum;

  @Column({
    type: 'enum',
    enum: SubstitutionEnum,
    nullable: true,
  })
  public substitution!: SubstitutionEnum | null;

  @Column({
    name: 'substitution_elapsed',
  })
  public substitutionElapsed!: number;

  @Column({
    name: 'substitution_elapsed_plus',
  })
  public substitutionElapsedPlus!: number;

  @Column()
  public price!: number;

  @Column()
  public point!: number;

  @OneToMany(() => IncidentHasPoint, (item) => item.eventLineUp)
  public incidentPoint!: IIncidentHasPoint[];

  @OneToOne(() => PlayersEmaHistory, (item) => item.lineUp)
  public emaPointHistory!: IPlayersEmaHistory | null;

  @Column({
    name: 'ema_point',
  })
  public emaPoint!: number | null;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;
}
