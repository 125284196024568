import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';

import { BillingAccountData } from '../../../components/account-form/account-form.component';
import { BillingAccountListComponent } from '../billing-account-list.component';

export const saveAccountEffect = new InjectionToken<
  Subject<BillingAccountData>
>('Save Account Effect', {
  providedIn: BillingAccountListComponent,
  factory: () => new Subject<BillingAccountData>(),
});
