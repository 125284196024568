import { inject } from '@angular/core';
import { ObserveFunc } from '@matchman/common-front';
import { TournamentTemplates } from '@matchman/database/entities';
import { endWithAsyncPipe } from '@matchman/frontend-utils';
import { setProp } from '@ngneat/elf';
import { upsertEntities } from '@ngneat/elf-entities';
import { catchError, map, of, startWith, switchMap, throwError } from 'rxjs';
import { JsonApiSdkService } from 'json-api-nestjs-sdk';

import { SearchFormState, tournamentTemplatesEntitiesRef } from '../index';
import { StateService } from '../../service/state.service';
import { loadTournamentEffect } from '../../effect';

export function loadTournamentTemplate(
  this: StateService
): ObserveFunc<SearchFormState> {
  const loadTournament = inject(loadTournamentEffect);
  const jsonApiUtilsService = inject(JsonApiSdkService);

  const loadTournamentTemplates$ = jsonApiUtilsService
    .getAll(TournamentTemplates)
    .pipe(
      map((r) =>
        r.map((i) => {
          i.id = parseInt(`${i.id}`, 10);
          return i;
        })
      ),
      map((r) =>
        this.reduceForUpdate(
          upsertEntities(r, { ref: tournamentTemplatesEntitiesRef })
        )
      ),

      startWith(
        this.reduceForUpdate(
          setProp('statusTournamentTemplates', (state) => ({
            ...state,
            ...{
              isLoading: true,
              status: 'idle' as typeof state['status'],
            },
          }))
        )
      ),
      endWithAsyncPipe(
        this.reduceForUpdate(
          setProp('statusTournamentTemplates', (state) => ({
            ...state,
            ...{
              isLoading: false,
              isSuccess: true,
              status: 'done' as typeof state['status'],
            },
          }))
        )
      ),
      catchError((err) => {
        return of(
          this.reduceForUpdate(
            setProp('statusTournamentTemplates', (state) => ({
              ...state,
              ...{
                isLoading: false,
                isSuccess: false,
                isError: true,
                status: 'done' as typeof state['status'],
                error: err,
              },
            }))
          )
        );
      })
    );

  return loadTournament.pipe(switchMap(() => loadTournamentTemplates$));
}
