import { EnvironmentProviders, Provider } from '@angular/core';
import { ConfigApplication } from '../types';

import routerProvider from './router.provider';
import authProvider from './auth.provider';
import animationProvider from './animation.provider';
import nestSdkProvider from './nest-sdk.provider';
import nzIconProvider from './nz-icon.provider';

export function initProviders(
  config: ConfigApplication
): Array<Provider | EnvironmentProviders> {
  return [
    routerProvider,
    ...authProvider(config),
    animationProvider,
    nestSdkProvider(config),
    nzIconProvider,
  ];
}
