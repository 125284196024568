import { ChangeDetectionStrategy, Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-event',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './event.component.html',
  styleUrls: ['./event.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventComponent {}
