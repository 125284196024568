import {
  Column,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryGeneratedColumn,
  RelationId,
} from 'typeorm';
import {
  Events,
  IEvents,
  Users,
  IUsers,
  JoinUserContest,
  IJoinUserContest,
} from './';
import { ContestTable } from './contest-default';

import { EventStatusEnum } from '../types';

export type IContest = Contest;

@Entity('contest')
export class Contest extends ContestTable {
  @PrimaryGeneratedColumn()
  override id!: number;

  @RelationId((item: Contest) => item.event)
  @Column({
    name: 'event_id',
  })
  eventId!: number;

  @ManyToOne(() => Events, (item) => item.id)
  @JoinColumn({
    name: 'event_id',
    referencedColumnName: 'id',
  })
  event!: IEvents;

  @RelationId((item: Contest) => item.user)
  @Column({
    name: 'user_id',
  })
  userId!: number;

  @ManyToOne(() => Users, (item) => item.id)
  @JoinColumn({
    name: 'user_id',
    referencedColumnName: 'id',
  })
  user!: IUsers;

  @OneToMany(
    () => JoinUserContest,
    (joinUserContest) => joinUserContest.contest
  )
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'contest_id',
  })
  public joinUserContest!: IJoinUserContest[];

  @Column({
    type: 'varchar',
  })
  status!: EventStatusEnum;

  @Column({
    type: 'int',
    name: 'members_count',
  })
  membersCount!: number;
}
