<nz-table
  *rxLet="vm$; let vm"
  appResizeTable
  [nzBordered]="true"
  [nzData]="vm.demoEventList"
  [nzLoading]="vm.loading"
  [nzFrontPagination]="false"
  nzSize="small"
>
  <thead>
  <tr>
    <th>Event</th>
    <th>Days</th>
    <th>Time</th>
    <th></th>
  </tr>
  </thead>
  <tbody>
  <tr *ngFor="let demoEvent of vm.demoEventList; trackBy: trackBy">
    <td>
      {{ demoEvent.events.homeTeam.name }} - {{demoEvent.events.awayTeam.name}}
    </td>
    <td>{{demoEvent.dayOfWeek | showDayOfWeek}}</td>
    <td>
      <nz-tag [nzColor]="'blue'" *ngFor="let item of demoEvent.timeOfDay; trackBy: trackBy">{{item | showTime}}</nz-tag>
    </td>
    <td> <a nz-button nzType="link">Edit</a> </td>
  </tr>
</nz-table>
