import {
  Column,
  Entity,
  JoinColumn,
  ManyToOne,
  PrimaryGeneratedColumn,
  RelationId,
  UpdateDateColumn,
} from 'typeorm';
import { Currency, MetaType } from '../types';

import { BillingAccount, IBillingAccount } from './billing-account';

export type IBillingTransaction = BillingTransaction;

@Entity('billing_transaction')
export class BillingTransaction {
  @PrimaryGeneratedColumn()
  public id!: number;

  @RelationId((item: BillingTransaction) => item.fromAccount)
  @Column({
    name: 'from_account_id',
  })
  public fromAccountId!: number;

  @ManyToOne(() => BillingAccount, (item) => item.fromAccount)
  @JoinColumn({
    name: 'from_account_id',
    referencedColumnName: 'id',
  })
  public fromAccount!: IBillingAccount;

  @RelationId((item: BillingTransaction) => item.toAccount)
  @Column({
    name: 'to_account_id',
  })
  public toAccountId!: number;

  @ManyToOne(() => BillingAccount, (item) => item.toAccount)
  @JoinColumn({
    name: 'to_account_id',
    referencedColumnName: 'id',
  })
  public toAccount!: IBillingAccount;

  @Column({
    name: 'amount_currency',
    enum: Currency,
    type: 'enum',
  })
  public amountCurrency!: Currency;

  @Column({
    name: 'amount_value',
  })
  public amountValue!: number;

  @Column()
  public details!: string;

  @Column({
    type: 'json',
  })
  public meta!: MetaType;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;
}
