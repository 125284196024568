import { FetchStatus } from '../../types';

export const defaultStatus = (): FetchStatus =>
  Object.assign({
    isError: false,
    isLoading: false,
    isSuccess: false,
    status: 'none',
    error: null,
  });
