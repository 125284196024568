import { map, MonoTypeOperatorFunction, pipe } from 'rxjs';
import { PositionEnum } from '@matchman/database/types';
import { EventsLineUp } from '@matchman/database/entities';

type PrePareObject = {
  [key in PositionEnum]?: EventsLineUp[];
};

const positionSortMap: {
  [key in PositionEnum]: number;
} = {
  [PositionEnum.Goalkeeper]: 1,
  [PositionEnum.Defence]: 2,
  [PositionEnum.Midfield]: 3,
  [PositionEnum.Forward]: 4,
  [PositionEnum.Unknown]: 5,
  [PositionEnum.SubstitutePlayer]: 5,
  [PositionEnum.Injured]: 6,
  [PositionEnum.Coach]: 7,
};

function sortByPrice(a: EventsLineUp, b: EventsLineUp): number {
  if (a.price > b.price) {
    return -1;
  }
  return 1;
}

function sortByPosition(a: EventsLineUp, b: EventsLineUp): number {
  if (
    positionSortMap[a.players.positionName] <
    positionSortMap[b.players.positionName]
  ) {
    return -1;
  }
  return 1;
}

function sortLineUp(lineUp: EventsLineUp[]): EventsLineUp[] {
  const prepareLineUp = lineUp.reduce<PrePareObject>(
    (acum, item) => {
      acum[item.positionName]?.push(item);
      return acum;
    },
    {
      [PositionEnum.Goalkeeper]: [],
      [PositionEnum.Defence]: [],
      [PositionEnum.Midfield]: [],
      [PositionEnum.Forward]: [],
      [PositionEnum.SubstitutePlayer]: [],
      [PositionEnum.Unknown]: [],
      [PositionEnum.Injured]: [],
    }
  );
  const {
    Goalkeeper = [],
    Defence = [],
    Midfield = [],
    Forward = [],
    Unknown = [],
    Injured = [],
  } = prepareLineUp;
  const SubstitutePlayer = prepareLineUp[PositionEnum.SubstitutePlayer] || [];
  return [
    ...Goalkeeper,
    ...Defence.sort(sortByPrice),
    ...Midfield.sort(sortByPrice),
    ...Forward.sort(sortByPrice),
    ...SubstitutePlayer.sort(sortByPosition),
    ...Injured.sort(sortByPosition),
    ...Unknown.sort(sortByPosition).filter(
      (i) => i.players.positionName !== PositionEnum.Unknown
    ),
  ];
}

export function sortPlayer(
  showOnlyMainLineUp = false
): MonoTypeOperatorFunction<EventsLineUp[]> {
  return pipe(
    map((lineUp) => {
      const { inLineUp, inNotLineUp } = lineUp.reduce<{
        inLineUp: EventsLineUp[];
        inNotLineUp: EventsLineUp[];
      }>(
        (acum, item) => {
          const type = item.shirtNumber === 0 ? 'inNotLineUp' : 'inLineUp';
          acum[type].push(item);
          return acum;
        },
        { inLineUp: [], inNotLineUp: [] }
      );
      return [...sortLineUp(inLineUp), ...sortLineUp(inNotLineUp)];
    }),
    map((lineUp) => {
      if (showOnlyMainLineUp) {
        return lineUp.filter(
          (i) => !['Injured', 'Unknown'].includes(i.positionName)
        );
      }

      return lineUp;
    })
  );
}
