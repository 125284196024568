import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzBreadCrumbModule } from 'ng-zorro-antd/breadcrumb';
import { NzIconModule } from 'ng-zorro-antd/icon';
import {
  ActivatedRoute,
  ActivationEnd,
  Params,
  PRIMARY_OUTLET,
  Router,
  RouterLink,
} from '@angular/router';
import { filter, map, Observable, startWith } from 'rxjs';

export type BreadcrumbOption = {
  label: string;
  params: Params;
  url: string;
};

@Component({
  selector: 'app-breadcrumb',
  standalone: true,
  imports: [CommonModule, NzBreadCrumbModule, NzIconModule, RouterLink],
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadcrumbComponent {
  router = inject(Router);
  activatedRoute = inject(ActivatedRoute);

  breadcrumbs: Observable<BreadcrumbOption[]> = this.router.events.pipe(
    filter((e) => e instanceof ActivationEnd),
    startWith(true),
    map(() => this.getBreadcrumbs(this.activatedRoute.root))
  );

  private getBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: BreadcrumbOption[] = []
  ) {
    const children: ActivatedRoute[] = route.children;
    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      if (child.outlet !== PRIMARY_OUTLET) {
        continue;
      }
      if (!child || !child.snapshot) {
        continue;
      }
      const routeUrl: string = child.snapshot.url
        .map((segment) => segment.path)
        .filter((path) => path)
        .join('/');

      const nextUrl = routeUrl ? `${url}/${routeUrl}` : url;
      const breadcrumbLabel = child.snapshot.data['breadcrumb'];
      if (routeUrl && breadcrumbLabel) {
        const breadcrumb: BreadcrumbOption = {
          label: breadcrumbLabel,
          params: child.snapshot.params,
          url: nextUrl,
        };
        breadcrumbs.push(breadcrumb);
      }

      this.getBreadcrumbs(child, nextUrl, breadcrumbs);
    }

    return breadcrumbs;
  }
}
