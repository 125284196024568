<nz-layout class="layout">
  <nz-header nz-row>
    <app-header nz-col nzSpan="2"></app-header>
    <app-menu nz-col nzSpan="22"></app-menu>
  </nz-header>
  <nz-content>
    <nz-layout class="inner-layout">
      <app-breadcrumb></app-breadcrumb>
      <nz-content>
        <router-outlet></router-outlet>
      </nz-content>
    </nz-layout>
  </nz-content>
  <nz-footer>Matchman CRM ©{{year}}</nz-footer>
</nz-layout>
