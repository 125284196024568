import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObserveFunc } from '@matchman/common-front';
import {
  endWithAsyncPipe,
  getNumberFromParamsPipe,
} from '@matchman/frontend-utils';
import { IncidentHasPoint } from '@matchman/database/entities';
import { setProp } from '@ngneat/elf';
import { setEntities } from '@ngneat/elf-entities';
import { map, switchMap } from 'rxjs/operators';
import { FilterOperand } from 'json-api-nestjs/filter-operand';
import { JsonApiSdkService } from 'json-api-nestjs-sdk';
import { QueryField } from 'json-api-nestjs';
import { catchError, of, startWith } from 'rxjs';

import { StateService } from '../../service/state.service';
import { IncidentPlayerState, incidentEntitiesRef } from '../';

export function loadIncident(
  this: StateService
): ObserveFunc<IncidentPlayerState> {
  const activatedRoute = inject(ActivatedRoute);
  const jsonApiUtilsService = inject(JsonApiSdkService);

  return activatedRoute.paramMap.pipe(
    getNumberFromParamsPipe('id'),
    switchMap((id) =>
      jsonApiUtilsService
        .getAll(IncidentHasPoint, {
          [QueryField.filter]: {
            target: {
              eventLineUpId: { [FilterOperand.eq]: `${id}` },
            },
          },
          [QueryField.include]: ['incident'],
        })
        .pipe(
          map((r) => setEntities(r, { ref: incidentEntitiesRef })),
          startWith(
            this.reduceForUpdate(
              setProp('statusIncident', (state) => ({
                ...state,
                ...{
                  isLoading: true,
                  status: 'idle' as typeof state['status'],
                },
              }))
            )
          ),
          endWithAsyncPipe(
            this.reduceForUpdate(
              setProp('statusIncident', (state) => ({
                ...state,
                ...{
                  isLoading: false,
                  isSuccess: true,
                  status: 'done' as typeof state['status'],
                },
              }))
            )
          ),
          catchError((err) => {
            return of(
              this.reduceForUpdate(
                setProp('statusIncident', (state) => ({
                  ...state,
                  ...{
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    status: 'done' as typeof state['status'],
                    error: err,
                  },
                }))
              )
            );
          })
        )
    )
  );
}
