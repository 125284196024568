import {
  Entity,
  PrimaryGeneratedColumn,
  Column,
  RelationId,
  ManyToOne,
  JoinColumn,
} from 'typeorm';

import {
  EventsLineUp,
  IEventsLineUp,
  EventIncidents,
  IEventIncidents,
} from './';

export type IIncidentHasPoint = IncidentHasPoint;

@Entity('incident_has_point')
export class IncidentHasPoint {
  @PrimaryGeneratedColumn()
  public id!: number;

  @RelationId((item: IncidentHasPoint) => item.eventLineUp)
  @Column({
    name: 'event_line_up_id',
  })
  public eventLineUpId!: number;

  @ManyToOne(() => EventsLineUp, (item) => item.incidentPoint)
  @JoinColumn({
    name: 'event_line_up_id',
    referencedColumnName: 'id',
  })
  public eventLineUp!: IEventsLineUp;

  @RelationId((item: IncidentHasPoint) => item.incident)
  @Column({
    name: 'incident_id',
  })
  public incidentId!: number;

  @ManyToOne(() => EventIncidents, (item) => item.incidentPoint)
  @JoinColumn({
    name: 'incident_id',
    referencedColumnName: 'id',
  })
  public incident!: IEventIncidents;

  @Column()
  public point!: number;
}
