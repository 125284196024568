import { Injectable } from '@angular/core';
import { selectAllEntities } from '@ngneat/elf-entities';
import { select } from '@ngneat/elf';
import { OnStoreInit, StoreService } from '@matchman/common-front';
import {
  TournamentTemplates,
  Tournaments,
  Teams,
} from '@matchman/database/entities';
import { combineLatest, map, shareReplay, startWith } from 'rxjs';

import {
  SearchFormState,
  tournamentTemplatesEntitiesRef,
  tournamentsEntitiesRef,
  teamsEntitiesRef,
} from '../state';

@Injectable()
export class StateService
  extends StoreService<SearchFormState>
  implements OnStoreInit
{
  private selectTournamentTemplatesItems = this.pipe(
    selectAllEntities({ ref: tournamentTemplatesEntitiesRef }),
    startWith([] as TournamentTemplates[]),
    shareReplay({ refCount: true })
  );

  private selectTournamentTemplatesProps = this.pipe(
    select((state) => state.statusTournamentTemplates),
    shareReplay({ refCount: true })
  );

  private selectTournamentItems = this.pipe(
    selectAllEntities({ ref: tournamentsEntitiesRef }),
    map((r) =>
      r.sort(
        (a, b) =>
          new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
      )
    ),
    startWith([] as Tournaments[]),
    shareReplay({ refCount: true })
  );

  private selectTournamentProps = this.pipe(
    select((state) => state.statusTournament),
    shareReplay({ refCount: true })
  );

  private selectTeamsItems = this.pipe(
    selectAllEntities({ ref: teamsEntitiesRef }),
    startWith([] as Teams[]),
    shareReplay({ refCount: true })
  );

  private selectTeamsProps = this.pipe(
    select((state) => state.statusTeam),
    shareReplay({ refCount: true })
  );

  public vm$ = combineLatest([
    this.selectTournamentTemplatesItems,
    this.selectTournamentTemplatesProps,
    this.selectTournamentItems,
    this.selectTournamentProps,
    this.selectTeamsItems,
    this.selectTeamsProps,
  ]).pipe(
    map(
      ([
        tournamentTemplatesItem,
        tournamentTemplatesProps,
        tournamentItems,
        tournamentProps,
        teamsItems,
        teamsProp,
      ]) => ({
        tournamentTemplatesItem,
        tournamentTemplatesProps,
        tournamentItems,
        tournamentProps,
        teamsItems,
        teamsProp,
      })
    )
  );
  onStoreInit(): void {}
}
