import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showTime',
  standalone: true,
})
export class ShowTimePipe implements PipeTransform {
  transform(value: string): string {
    return value
      .split(':')
      .filter((i, k) => k < 2)
      .join(':');
  }
}
