import { ObserveFunc } from '@matchman/common-front';
import { inject } from '@angular/core';
import { EventIncidentType } from '@matchman/database/entities';
import { endWithAsyncPipe } from '@matchman/frontend-utils';
import { setProp } from '@ngneat/elf';
import { setEntities } from '@ngneat/elf-entities';
import { map, switchMap } from 'rxjs/operators';
import { QueryField } from 'json-api-nestjs';
import { JsonApiSdkService } from 'json-api-nestjs-sdk';
import { catchError, of, startWith } from 'rxjs';

import { StateService } from '../../service/state.service';
import { LineUpState, eventIncidentTypeEntitiesRef } from '../index';
import { loadEventIncidentTypeEffect } from '../../effect';

export function loadEventIncidentType(
  this: StateService
): ObserveFunc<LineUpState> {
  const loadEventIncidentType = inject(loadEventIncidentTypeEffect);
  const jsonApiUtilsService = inject(JsonApiSdkService);

  return loadEventIncidentType.asObservable().pipe(
    switchMap(() =>
      jsonApiUtilsService
        .getAll(
          EventIncidentType,
          {
            [QueryField.fields]: { target: ['id', 'describe'] },
            [QueryField.page]: { number: 1, size: 1000 },
          },
          true
        )
        .pipe(
          map((r) =>
            this.reduceForUpdate(
              setEntities(r, { ref: eventIncidentTypeEntitiesRef })
            )
          ),
          startWith(
            this.reduceForUpdate(
              setProp('statusEventIncidentType', (state) => ({
                ...state,
                ...{
                  isLoading: true,
                  status: 'idle' as typeof state['status'],
                },
              }))
            )
          ),
          endWithAsyncPipe(
            this.reduceForUpdate(
              setProp('statusEventIncidentType', (state) => ({
                ...state,
                ...{
                  isLoading: false,
                  isSuccess: true,
                  status: 'done' as typeof state['status'],
                },
              }))
            )
          ),
          catchError((err) => {
            return of(
              this.reduceForUpdate(
                setProp('statusEventIncidentType', (state) => ({
                  ...state,
                  ...{
                    isLoading: false,
                    isSuccess: false,
                    isError: true,
                    status: 'done' as typeof state['status'],
                    error: err,
                  },
                }))
              )
            );
          })
        )
    )
  );
}
