import {
  Column,
  Entity,
  OneToMany,
  PrimaryColumn,
  UpdateDateColumn,
} from 'typeorm';
import { IPlayers, Players } from './';
import { PositionTypeEnum } from '../types';

export type IPositionsType = PositionsType;

@Entity('positions_type')
export class PositionsType {
  @PrimaryColumn()
  public id!: number;

  @Column({
    type: 'enum',
    enum: PositionTypeEnum,
  })
  public name!: PositionTypeEnum;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;

  @OneToMany(() => Players, (item) => item.position)
  public players!: IPlayers[];
}
