<nz-table
  *rxLet="vm$; let vm"
  appResizeTable
  [nzData]="vm.incidentItems"
  [nzLoading]="vm.loading"
  [nzBordered]="true"
  nzSize="small"
  [nzVirtualItemSize]="39"
  [nzVirtualForTrackBy]="trackBy"
  [nzFrontPagination]="false"
  [nzShowPagination]="false"
>
  <thead>
  <tr>
    <th rowspan="3" nzLeft nzWidth="60px"></th>
    <th rowspan="3" nzLeft nzWidth="800px">Text incident</th>
    <th colspan="12">Point</th>
  </tr>
  <tr>
    <th colspan="3" nzAlign="center">GK</th>
    <th colspan="3" nzAlign="center">DF</th>
    <th colspan="3" nzAlign="center">ML</th>
    <th colspan="3" nzAlign="center">FR</th>
  </tr>
  <tr>
    <th nzAlign="center">P1</th>
    <th nzAlign="center">P2</th>
    <th nzAlign="center">T</th>

    <th nzAlign="center">P1</th>
    <th nzAlign="center">P2</th>
    <th nzAlign="center">T</th>

    <th nzAlign="center">P1</th>
    <th nzAlign="center">P2</th>
    <th nzAlign="center">T</th>

    <th nzAlign="center">P1</th>
    <th nzAlign="center">P2</th>
    <th nzAlign="center">T</th>
  </tr>
  </thead>
  <tbody>
  <ng-template nz-virtual-scroll let-data let-index="index">
    <tr>
      <td
        [nzShowExpand]="true"
        (nzExpandChange)="onExpandChange(data.id, $event)"
      ></td>
      <td>
        <div
          [ngClass]="['editable-point']"
          *ngIf="
                !(
                  vm.editableIncidentText !== null &&
                  vm.editableIncidentText.idIncident === data.id
                )
              "
          (click)="startEditIncident($event, data)"
        >
          {{ data.describe | emptyString: 'Empty description' }}
        </div>
        <input
          nz-input
          *ngIf="
                !(
                  vm.editableIncidentText === null ||
                  vm.editableIncidentText.idIncident !== data.id
                )
              "
          [(ngModel)]="vm.editableIncidentText.value"
          (blur)="stopIncidentEdit(vm.editableIncidentText)"
        />
      </td>
      <td
        *ngFor="let pointItem of data | incidentPointToArray; let i = index"
        [ngClass]="{ 'editable-td-point': pointItem }"
        nzAlign="center"
      >
        <div
          [ngClass]="{
                'editable-point': pointItem,
                'transparent-point': pointItem === false
              }"
          (click)="startEdit(data, i, pointItem)"
          *ngIf="
                !(
                  vm.editablePoint !== null &&
                  vm.editablePoint.idIncident === data.id &&
                  vm.editablePoint.indexPoint === i
                )
              "
        >
          {{ pointItem | emptyString: 'n/a' }}
        </div>
        <nz-input-number
          *ngIf="
                !(
                  vm.editablePoint === null ||
                  !(
                    vm.editablePoint.idIncident === data.id &&
                    vm.editablePoint.indexPoint === i
                  )
                )
              "
          [(ngModel)]="vm.editablePoint.value"
          (nzBlur)="stopEdit(vm.editablePoint, data)"
          [nzAutoFocus]="true"
        ></nz-input-number>
      </td>
    </tr>
    <tr [nzExpand]="vm.expandRow === data.id">
      <div *ngFor="let i of data.eventIncidentTypeText; trackBy: trackBy">
        {{ i.name }}
      </div>
    </tr>
  </ng-template>
  </tbody>
</nz-table>
