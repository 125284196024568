import { Injectable } from '@angular/core';
import { OnStoreInit, StoreService } from '@matchman/common-front';
import { select } from '@ngneat/elf';
import { selectAllEntities } from '@ngneat/elf-entities';
import { Events } from '@matchman/database/entities';
import { map } from 'rxjs/operators';
import { shareReplay, combineLatest, startWith } from 'rxjs';

import { EventListState, eventsEntitiesRef } from '../state';

@Injectable()
export class StateService
  extends StoreService<EventListState>
  implements OnStoreInit
{
  private pageInfo$ = this.pipe(
    select((state) => state.pageInfo),
    shareReplay({ refCount: true })
  );
  private eventsItem$ = this.pipe(
    selectAllEntities({ ref: eventsEntitiesRef }),
    map((item) =>
      item.sort((a, b) => {
        const timeA = new Date(b.startDate).getTime();
        const timeB = new Date(b.startDate).getTime();
        return timeA < timeB ? 1 : -1;
      })
    ),
    startWith([] as Events[]),
    shareReplay({ refCount: true })
  );

  private loadStatus$ = this.pipe(select((state) => state.statusEvents));

  vm$ = combineLatest([
    this.pageInfo$,
    this.eventsItem$,
    this.loadStatus$,
  ]).pipe(
    map(([pageInfo, eventsItem, loadStatus]) => ({
      pageInfo,
      eventsItem,
      loadStatus,
    }))
  );

  onStoreInit(): void {}
}
