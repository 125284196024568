import {
  Column,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryColumn,
  RelationId,
  UpdateDateColumn,
} from 'typeorm';
import {
  Countries,
  ICountries,
  PositionsType,
  IPositionsType,
  ITeamsHasPlayers,
  TeamsHasPlayers,
  EventsLineUp,
  IEventsLineUp,
  IEventIncidentDetail,
  EventIncidentsDetail,
  PlayersEmaHistory,
  IPlayersEmaHistory,
  IPlayerUserContest,
  PlayerUserContest,
} from './';
import { GenderEnum, PositionEnum, PositionTypeEnum } from '../types';

export type IPlayers = Players;

@Entity('players')
export class Players {
  @PrimaryColumn()
  public id!: number;

  @Column()
  public name!: string;

  @Column({
    name: 'first_name',
  })
  public firstName!: string;

  @Column({
    name: 'last_name',
  })
  public lastName!: string;

  @ManyToOne(() => Countries, (item) => item.name)
  @JoinColumn({
    name: 'country_name',
    referencedColumnName: 'name',
  })
  public country!: ICountries;

  @RelationId((item: Players) => item.country)
  @Column({
    name: 'country_name',
  })
  public countryName!: string;

  @Column({
    type: 'enum',
    enum: GenderEnum,
    nullable: false,
  })
  public gender!: GenderEnum;

  @Column({
    name: 'country_code',
  })
  public countryCode!: string;

  @ManyToOne(() => PositionsType, (item) => item.name)
  @JoinColumn({
    name: 'position_name',
    referencedColumnName: 'name',
  })
  public position!: IPositionsType;

  @RelationId((item: Players) => item.position)
  @Column({
    type: 'enum',
    enum: PositionTypeEnum,
    nullable: false,
    name: 'position_name',
  })
  public positionName!: PositionEnum;

  @Column({
    name: 'date_of_birth',
  })
  public dateOfBirth!: Date;

  @Column()
  public height!: number;

  @Column()
  public weight!: number;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;

  @OneToMany(() => TeamsHasPlayers, (i) => i.player)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'player_id',
  })
  public teamsHasPlayers!: ITeamsHasPlayers[];

  @OneToMany(() => EventsLineUp, (i) => i.players)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'player_id',
  })
  public lineUp!: IEventsLineUp[];

  @OneToMany(() => EventIncidentsDetail, (item) => item.player)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'player_id',
  })
  public eventIncidentDetail!: IEventIncidentDetail[];

  @OneToMany(() => PlayersEmaHistory, (item) => item.player)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'player_id',
  })
  public emaHistory!: IPlayersEmaHistory;

  @OneToMany(() => PlayerUserContest, (item) => item.player)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'player_id',
  })
  public playerUserContest!: IPlayerUserContest[];
}
