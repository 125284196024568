import {
  Column,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryColumn,
  RelationId,
  UpdateDateColumn,
} from 'typeorm';
import { ICountries, Countries, Teams, ITeams } from './';

export type IVenues = Venues;

@Entity('venues')
export class Venues {
  @PrimaryColumn()
  public id!: number;

  @Column()
  public name!: string;

  @ManyToOne(() => Countries, (item) => item.name)
  @JoinColumn({
    name: 'country_name',
    referencedColumnName: 'name',
  })
  public country!: ICountries;

  @RelationId((item: Venues) => item.country)
  @Column({
    name: 'country_name',
  })
  public countryName!: string;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;

  @OneToMany(() => Teams, (item) => item.venue)
  public teams!: ITeams[];
}
