import {
  Column,
  Entity,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';
import { ContestType } from '../types';
export abstract class ContestTable {
  @PrimaryGeneratedColumn()
  id!: number;

  @Column({
    name: 'contest_type',
    type: 'varchar',
  })
  public contestType!: ContestType;

  @Column()
  public payment!: number;

  @Column({
    type: 'int',
    array: true,
  })
  public fee!: number[];

  @Column()
  public seats!: number;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;
}

export type IContestDefault = ContestDefault;

@Entity('contest_default')
export class ContestDefault extends ContestTable {}
