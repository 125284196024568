import {
  Column,
  Entity,
  JoinColumn,
  ManyToOne,
  PrimaryColumn,
  RelationId,
  UpdateDateColumn,
} from 'typeorm';

import { EventTextType } from '../types';

import {
  IEventIncidents,
  EventIncidents,
  Players,
  IPlayers,
  ITeams,
  Teams,
} from './';

export type IEventIncidentDetail = EventIncidentsDetail;

@Entity('event_incidents_detail')
export class EventIncidentsDetail {
  @PrimaryColumn()
  public id!: number;

  @RelationId((item: EventIncidentsDetail) => item.eventIncident)
  @Column({
    name: 'event_incident_id',
  })
  public eventIncidentId!: number;

  @ManyToOne(() => EventIncidents, (item) => item.id)
  @JoinColumn({
    name: 'event_incident_id',
  })
  public eventIncident!: IEventIncidents;

  @Column({
    type: 'enum',
    enum: EventTextType,
    nullable: false,
  })
  public type!: EventTextType;

  @RelationId((item: EventIncidentsDetail) => item.player)
  @Column({
    name: 'player_id',
  })
  playerId!: number;

  @ManyToOne(() => Players, (item) => item.id)
  @JoinColumn({
    name: 'player_id',
  })
  public player!: IPlayers;

  @RelationId((item: EventIncidentsDetail) => item.team)
  @Column({
    name: 'team_id',
  })
  public teamId!: number;

  @ManyToOne(() => Teams, (item) => item.id)
  @JoinColumn({
    name: 'team_id',
  })
  public team!: ITeams;

  @Column()
  public value!: string;

  @Column({
    name: 'is_remove',
  })
  public isRemove!: boolean;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;
}
