import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  inject,
  OnDestroy,
  OnInit,
  Renderer2,
} from '@angular/core';
import { NzTableComponent } from 'ng-zorro-antd/table';
import { distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[appResizeTable]',
  standalone: true,
})
export class ResizeTableDirective<T>
  implements AfterViewInit, OnDestroy, OnInit
{
  protected ngTable = inject(NzTableComponent);
  protected renderer = inject(Renderer2);
  protected elementRef = inject(ElementRef);
  protected changeDetectorRef = inject(ChangeDetectorRef);

  protected resizeSubject = new Subject<number>();
  protected destroy$: Subject<boolean> = new Subject<boolean>();
  protected resizeObserver: ResizeObserver;

  ngAfterViewInit() {
    const parent = this.elementRef.nativeElement.parentNode;
    const divElement = this.renderer.createElement('div');
    this.renderer.setAttribute(
      divElement,
      'style',
      'max-height: 100%;overflow: hidden;flex: 1 0 0;'
    );
    this.renderer.addClass(divElement, 'wrapper-table');

    this.renderer.insertBefore(
      parent,
      divElement,
      this.elementRef.nativeElement
    );

    this.renderer.appendChild(divElement, this.elementRef.nativeElement);
    this.renderer.removeAttribute(
      this.elementRef.nativeElement,
      'appResizeTable'
    );

    this.resizeObserver = new ResizeObserver(() =>
      this.resizeSubject.next(divElement.offsetHeight)
    );
    this.resizeObserver.observe(divElement);
  }

  ngOnInit() {
    this.ngTable.nzScroll = { x: '0px', y: '0px' };
    this.changeDetectorRef.detectChanges();
    this.resizeSubject
      .asObservable()
      .pipe(
        distinctUntilChanged(),
        map((height) => height - 85),
        map((height) => ({
          x: '0px',
          y: `${height}px`,
        })),
        takeUntil(this.destroy$)
      )
      .subscribe((r) => {
        this.ngTable.scrollX = r.x;
        this.ngTable.scrollY = r.y;
        this.changeDetectorRef.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.resizeObserver.disconnect();
    this.destroy$.next(true);
  }
}
