<ul
  nz-menu
  nzTheme="dark"
  nzMode="horizontal"
  *ngIf="currentUrl$ | async as currentUrl"
>
  <li nz-menu-item [nzSelected]="eventLink.isActive && currentUrl !== '/'">
    <a
      routerLinkActive="active"
      #eventLink="routerLinkActive"
      [routerLink]="['events']"
      >Events</a
    >
  </li>
  <li nz-menu-item [nzSelected]="billingLink.isActive && currentUrl !== '/'">
    <a
      routerLinkActive="active"
      #billingLink="routerLinkActive"
      [routerLink]="['billing']"
    >Billing</a
    >
  </li>
  <li nz-submenu nzTitle="Config" nzIcon="setting">
    <ul>
      <li
        nz-menu-item
        [nzSelected]="incidentLink.isActive && currentUrl !== '/'"
      >
        <a
          routerLinkActive="active"
          #incidentLink="routerLinkActive"
          [routerLink]="['config', 'incident']"
          >Incidents</a
        >
      </li>
      <li
        nz-menu-item
        [nzSelected]="demoEvent.isActive && currentUrl !== '/'"
      >
        <a
          routerLinkActive="active"
          #demoEvent="routerLinkActive"
          [routerLink]="['config', 'demo-event']"
        >Demo event</a
        >
      </li>
    </ul>
  </li>
</ul>
