import { ApplicationRef, enableProdMode, isDevMode } from '@angular/core';
import { bootstrapApplication } from '@angular/platform-browser';
import { enableElfProdMode } from '@ngneat/elf';
import { devTools } from '@ngneat/elf-devtools';

import { initProviders } from './app/providers';
import { ConfigApplication } from './app/types';
import { BootstrapComponent } from './app/components/bootstrap/bootstrap.component';

const isDev = isDevMode();
if (!isDev) {
  enableElfProdMode();
  enableProdMode();
}

fetch('./config.json')
  .then<ConfigApplication>((r) => r.json())
  .then((config) => initProviders(config))
  .then((providers) => bootstrapApplication(BootstrapComponent, { providers }))
  .then((moduleRef) => {
    if (!isDev) return;
    devTools({
      name: 'MatchmanCrm',
      postTimelineUpdate: () => moduleRef.injector.get(ApplicationRef).tick(),
    });
  })
  .catch((err) => console.error(err));
