import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';

import { DemoEventComponent } from '../demo-event.component';

export const loadDemoEventEffect = new InjectionToken<Subject<undefined>>(
  'LoadDemoEventEffect',
  {
    providedIn: DemoEventComponent,
    factory: () => new Subject(),
  }
);
