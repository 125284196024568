import {
  Column,
  Entity,
  JoinColumn,
  ManyToMany,
  ManyToOne,
  PrimaryColumn,
  RelationId,
  OneToMany,
  UpdateDateColumn,
} from 'typeorm';
import {
  Countries,
  ICountries,
  Venues,
  IVenues,
  TournamentStages,
  ITournamentStages,
  TeamsHasPlayers,
  Events,
  IEvents,
  EventsLineUp,
  IEventsLineUp,
  EventIncidentsDetail,
  IEventIncidentDetail,
} from './';
import { GenderEnum } from '../types';

export type ITeams = Teams;

@Entity('teams')
export class Teams {
  @PrimaryColumn()
  public id!: number;

  @Column()
  public name!: string;

  @Column({
    name: 'short_name',
  })
  public shortName!: string;

  @ManyToOne(() => Countries, (item) => item.name)
  @JoinColumn({
    name: 'country_name',
    referencedColumnName: 'name',
  })
  public country!: ICountries;

  @RelationId((item: Teams) => item.country)
  @Column({
    name: 'country_name',
  })
  public countryName!: string;

  @ManyToOne(() => Venues, (item) => item.name)
  @JoinColumn({
    name: 'venue_id',
    referencedColumnName: 'id',
  })
  public venue!: IVenues;

  @RelationId((item: Teams) => item.venue)
  @Column({
    name: 'venue_id',
  })
  public venueId!: number;

  @Column({
    type: 'enum',
    enum: GenderEnum,
    nullable: false,
  })
  public gender!: GenderEnum;

  @Column({
    name: 'is_national_team',
  })
  public isNationalTeam!: boolean;

  @Column({
    name: 'home_page',
  })
  public homePage!: string;

  @Column({
    name: 'home_shirt_color_1',
  })
  public homeShirtColor1!: string;

  @ManyToMany(() => TournamentStages, (item) => item.teams)
  public tournamentStages!: ITournamentStages[];

  @OneToMany(() => TeamsHasPlayers, (i) => i.team)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'team_id',
  })
  public teamsHasPlayers!: TeamsHasPlayers[];

  @OneToMany(() => TeamsHasPlayers, (i) => i.onLoanFromTeam)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'on_loan_from_team_id',
  })
  public onLoanFrom!: TeamsHasPlayers[];

  @OneToMany(() => TeamsHasPlayers, (i) => i.onLoanToTeam)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'on_loan_to_team_id',
  })
  public onLoanTo!: TeamsHasPlayers[];

  @OneToMany(() => EventsLineUp, (i) => i.teams)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'event_id',
  })
  public lineUp!: IEventsLineUp[];

  @ManyToMany(() => Events, (item) => item.homeTeam)
  public eventsHome!: IEvents[];

  @ManyToMany(() => Events, (item) => item.awayTeam)
  public awayHome!: IEvents[];

  @OneToMany(() => EventIncidentsDetail, (item) => item.team)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'team_id',
  })
  public eventIncidentDetail!: IEventIncidentDetail[];

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;
}
