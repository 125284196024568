import { Inject, Injectable, InjectionToken, Optional } from '@angular/core';
import { Store, Reducer, StoreDef } from '@ngneat/elf';
import {
  asyncScheduler,
  distinctUntilChanged,
  filter,
  map,
  mergeAll,
  Observable,
  observeOn,
  Subject,
  Subscription,
  tap,
} from 'rxjs';

export const INITIAL_STATE_TOKEN = new InjectionToken('init state default');

@Injectable()
export class StoreService<T = any> extends Store<StoreDef<T>> {
  public subscription = new Subscription();
  public stateObservables = new Subject<
    Observable<Reducer<T> | Reducer<T>[] | undefined>
  >();
  private signalFromConnect = this.stateObservables.pipe(
    distinctUntilChanged(),
    mergeAll(),
    observeOn(asyncScheduler),
    filter(
      (
        reducers: Reducer<T> | Reducer<T>[] | undefined
      ): reducers is Reducer<T> | Reducer<T>[] => !!reducers
    ),
    map((reducers) => (Array.isArray(reducers) ? reducers : [reducers])),
    filter((reducers) => reducers.length > 0),
    tap((reducers) => this.update(...reducers))
  );
  constructor(@Optional() @Inject(INITIAL_STATE_TOKEN) def: StoreDef<T>) {
    super(def);
    this.subscription.add(this.subscribeReduce());
  }

  connect(...input$: Observable<Reducer<T> | Reducer<T>[] | undefined>[]) {
    for (const inputItem$ of input$) {
      this.stateObservables.next(inputItem$);
    }
  }

  reduceForUpdate(...reducers: Array<Reducer<T>>): Array<Reducer<T>> {
    return reducers;
  }

  override destroy() {
    this.subscription.unsubscribe();
    super.destroy();
  }

  subscribeReduce() {
    const subscription = new Subscription();
    subscription.add(this.signalFromConnect.subscribe());
    return subscription;
  }
}
