import { inject, Injectable } from '@angular/core';
import { OnStoreInit, StoreService } from '@matchman/common-front';
import { select } from '@ngneat/elf';
import { selectAllEntities, selectEntity } from '@ngneat/elf-entities';
import { EventsLineUp, Players } from '@matchman/database/entities';
import { combineLatestWith, map, switchMap } from 'rxjs/operators';
import {
  shareReplay,
  combineLatest,
  startWith,
  filter,
  distinctUntilChanged,
} from 'rxjs';
import { IncidentListState, incidentEntitiesRef } from '../state';
import { sortPlayer } from '../../../utils';
import { StateService as LineUpStateService } from '../../line-up-for-event/service/state.service';
import { ActivatedRoute } from '@angular/router';
import { getNumberFromParamsPipe } from '@matchman/frontend-utils';

import { loadEventIncidentEffect } from '../effect';

@Injectable()
export class StateService
  extends StoreService<IncidentListState>
  implements OnStoreInit
{
  protected loadEventIncidentEffect = inject(loadEventIncidentEffect);

  protected incidentItems$ = this.pipe(
    selectAllEntities({ ref: incidentEntitiesRef }),
    shareReplay({ refCount: true })
  );

  protected loading$ = this.pipe(
    select((state) => state.statusIncidents.isLoading),
    distinctUntilChanged(),
    shareReplay({ refCount: true })
  );

  vm$ = combineLatest([this.incidentItems$, this.loading$]).pipe(
    map(([incidentItems, loading]) => ({ incidentItems, loading }))
  );

  onStoreInit(): void {
    this.loadEventIncidentEffect.next(void 0);
  }
}
