import { inject } from '@angular/core';
import { ObserveFunc } from '@matchman/common-front';
import {
  BillingTransaction,
  BillingAccount,
} from '@matchman/database/entities';
import { map, switchMap } from 'rxjs/operators';
import { JsonApiSdkService } from 'json-api-nestjs-sdk';
import { putMoneyEffect } from '../../effect';
import { accountListEntitiesRef, BillingAccountListState } from '../index';
import { StateService } from '../../service/state.service';
import { setEntities, upsertEntities } from '@ngneat/elf-entities';
import { setProp } from '@ngneat/elf';
import { catchError, of, startWith } from 'rxjs';
import { endWithAsyncPipe } from '@matchman/frontend-utils';

export function putMoney(
  this: StateService
): ObserveFunc<BillingAccountListState> {
  const putMoney = inject(putMoneyEffect);
  const jsonApiUtilsService = inject(JsonApiSdkService);

  return putMoney.asObservable().pipe(
    map((r) => {
      const billingTransaction = new BillingTransaction();
      billingTransaction.toAccountId = r.toAccountId;
      billingTransaction.fromAccountId = r.fromAccountId;
      billingTransaction.amountValue = r.amountValue;
      billingTransaction.details = 'From admin';
      return billingTransaction;
    }),
    switchMap((r) =>
      jsonApiUtilsService.postOne(r).pipe(
        switchMap(() =>
          jsonApiUtilsService.getAll(BillingAccount, {
            filter: {
              target: {
                id: {
                  in: [`${r.toAccountId}`, `${r.fromAccountId}`],
                },
              },
            },
          })
        ),
        map((r) =>
          r.map((i) => {
            i.id = parseInt(`${i.id}`, 10);
            return i;
          })
        ),
        map((r) =>
          this.reduceForUpdate(
            upsertEntities(r, { ref: accountListEntitiesRef })
          )
        ),
        startWith(this.reduceForUpdate(setProp('puttingMoney', true))),
        endWithAsyncPipe(this.reduceForUpdate(setProp('puttingMoney', false))),
        catchError((err) =>
          of(this.reduceForUpdate(setProp('puttingMoney', false)))
        )
      )
    )
  );
}
