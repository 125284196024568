import { inject, Injectable } from '@angular/core';
import { OnStoreInit, StoreService } from '@matchman/common-front';
import { select } from '@ngneat/elf';
import { selectAllEntities, selectEntity } from '@ngneat/elf-entities';
import { EventsLineUp, Players } from '@matchman/database/entities';
import { combineLatestWith, map, switchMap } from 'rxjs/operators';
import {
  shareReplay,
  combineLatest,
  startWith,
  filter,
  distinctUntilChanged,
} from 'rxjs';
import { IncidentPlayerState, incidentEntitiesRef } from '../state';
import { sortPlayer } from '../../../utils';
import { StateService as LineUpStateService } from '../../line-up-for-event/service/state.service';
import { ActivatedRoute } from '@angular/router';
import { getNumberFromParamsPipe } from '@matchman/frontend-utils';
import { lineUpEntitiesRef } from '../../line-up-for-event/state';

@Injectable()
export class StateService
  extends StoreService<IncidentPlayerState>
  implements OnStoreInit
{
  protected activatedRoute = inject(ActivatedRoute);
  protected lineUpStateService = inject(LineUpStateService, {
    skipSelf: true,
  });

  protected playerInfo$ = this.activatedRoute.paramMap.pipe(
    getNumberFromParamsPipe('id'),
    switchMap((id) =>
      this.lineUpStateService.pipe(
        selectEntity(id, { ref: lineUpEntitiesRef }),
        filter((lineUpEntity) => !!lineUpEntity),
        map((lineUpEntity) => lineUpEntity.players),
        distinctUntilChanged((a, b) => a.id === b.id),
        startWith(null as Players)
      )
    ),
    shareReplay({ refCount: true })
  );

  protected eventInfo$ = this.lineUpStateService.pipe(
    select((state) => state.event),
    shareReplay({ refCount: true })
  );

  protected loading$ = this.pipe(
    select((state) => state.statusIncident.isLoading),
    shareReplay({ refCount: true })
  );

  protected loadingType$ = this.lineUpStateService.pipe(
    select((state) => state.statusEventIncidentType.isLoading)
  );

  protected pointList$ = this.pipe(
    selectAllEntities({ ref: incidentEntitiesRef }),
    map((item) =>
      item.map((i) => ({
        point: i.point,
        typeId: i.incident.eventIncidentTypeFK,
      }))
    ),
    switchMap((items) =>
      this.lineUpStateService.pipe(
        select((state) => state.eventIncidentTypeEntities),
        map((eventType) =>
          items
            .filter((i) => !!eventType[i.typeId])
            .map((i) => ({ ...i, text: eventType[i.typeId].describe }))
        )
      )
    )
  );

  vm$ = combineLatest([
    this.loading$,
    this.loadingType$,
    this.playerInfo$,
    this.eventInfo$,
    this.pointList$,
  ]).pipe(
    map(([loading, loadingType, playerInfo, eventInfo, pointList]) => ({
      loading,
      loadingType,
      playerInfo,
      eventInfo,
      pointList,
    }))
  );
  onStoreInit(): void {}
}
