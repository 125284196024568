import { filter, map } from 'rxjs';
import {
  ArgsType,
  RpcHttpCall,
  RpcRequest,
  RpcResponse,
  RpcResponseError,
  WrapperType,
} from '../types';
import { RpcError } from './';

export function isErrorResponse<T extends () => any>(
  data: RpcResponse<T>
): data is RpcResponseError {
  return 'error' in data;
}

export class WrapperCall<T extends () => any> {
  constructor(private nameSpace: string, private method: string) {}

  callRpc(
    rpcHttpCall: RpcHttpCall<T>,
    ...arg: ArgsType<Required<T>>
  ): WrapperType<ReturnType<T>, ArgsType<Required<T>>> {
    const request: RpcRequest<T> = {
      jsonrpc: '2.0',
      params: arg,
      method: `${this.nameSpace}.${this.method}`,
      id: 1,
    };
    return {
      execute: () => {
        return rpcHttpCall(request).pipe(
          filter((r): r is RpcResponse<T> => !Array.isArray(r)),
          map((r) => {
            if (isErrorResponse(r)) {
              return new RpcError(
                r.error.code,
                r.error.message,
                r.error.detail
              );
            }
            return r.result;
          })
        );
      },
      rpcData: {
        nameSpace: this.nameSpace,
        method: this.method,
        arg,
      },
    };
  }
}
