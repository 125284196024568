import {
  concat,
  delay,
  MonoTypeOperatorFunction,
  Observable,
  startWith,
  EMPTY,
} from 'rxjs';

export function endWithAsyncPipe<T>(values: T): MonoTypeOperatorFunction<T> {
  const delayMap = EMPTY.pipe(startWith(values), delay(4));
  return (source: Observable<T>) => {
    return concat(source, delayMap) as Observable<T>;
  };
}
