import {
  Column,
  Entity,
  JoinColumn,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  UpdateDateColumn,
} from 'typeorm';
import { Contest, IContest } from './contest';
import { IJoinUserContest, JoinUserContest } from './join-user-contest';
import { BillingAccount, IBillingAccount } from './billing-account';

export type IUsers = Users;

@Entity('users')
export class Users {
  @PrimaryGeneratedColumn()
  public id!: number;

  @Column()
  public email!: string;

  @Column()
  public nickname!: string;

  @Column()
  public avatar!: string;

  @Column({
    name: 'auth0_id',
  })
  public auth0Id!: string;

  @Column({
    name: 'is_active',
  })
  isActive!: boolean;

  @OneToMany(() => Contest, (contest) => contest.user)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'user_id',
  })
  public contest!: IContest[];

  @OneToMany(() => JoinUserContest, (joinUserContest) => joinUserContest.user)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'user_id',
  })
  public joinUserContest!: IJoinUserContest[];

  @OneToOne(() => BillingAccount, (billingAccount) => billingAccount.user)
  public billingAccounts!: IBillingAccount;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;
}
