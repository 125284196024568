import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { provideStore } from '@matchman/common-front';
import {
  EventIncidentType,
  EventIncidentTypePoint,
  IEventIncidentTypeText,
} from '@matchman/database/entities';
import { PositionEnum } from '@matchman/database/types';
import { RxLet } from '@rx-angular/template/let';
import { getEntity } from '@ngneat/elf-entities';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzInputModule } from 'ng-zorro-antd/input';
import {
  filter,
  map,
  merge,
  scan,
  shareReplay,
  startWith,
  Subject,
  combineLatest,
} from 'rxjs';

import {
  incidentEntitiesRef,
  IncidentListState,
  observeEffect,
  options,
} from './state';
import { StateService } from './service/state.service';
import { ResizeTableDirective } from '../../directives/resize-table.directive';
import { EmptyStringPipe } from './pipes/empty-string.pipe';
import { GetFieldFromArrayPipe } from './pipes/get-field-from-array.pipe';
import { IncidentPointToArrayPipe } from './pipes/incident-point-to-array.pipe';

import {
  EditableIncidentText,
  EditablePoint,
  IncidentConfigVm,
} from '../../types';
import { saveIncidentEffect, saveIncidentPointEffect } from './effect';

type IndexToPoint = {
  player: 'player1' | 'player2' | 'team';
  type: Exclude<
    PositionEnum,
    | PositionEnum.Unknown
    | PositionEnum.SubstitutePlayer
    | PositionEnum.Coach
    | PositionEnum.Injured
  >;
};

@Component({
  selector: 'app-incident-list',
  standalone: true,
  imports: [
    CommonModule,
    NzTableModule,
    RxLet,
    ResizeTableDirective,
    FormsModule,
    NzInputNumberModule,
    EmptyStringPipe,
    GetFieldFromArrayPipe,
    IncidentPointToArrayPipe,
    NzInputModule,
  ],
  templateUrl: './incident-list.component.html',
  styleUrls: ['./incident-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ...provideStore<IncidentListState>(
      StateService,
      options,
      Object.values(observeEffect)
    ),
  ],
})
export class IncidentListComponent implements OnDestroy {
  protected stateService = inject(StateService);
  protected renderer = inject(Renderer2);
  protected saveIncident = inject(saveIncidentEffect);
  protected saveIncidentPoint = inject(saveIncidentPointEffect);

  protected expandRow = new Subject<number | null>();
  protected editablePoint = new Subject<EditablePoint | null>();
  protected editableIncidentText = new Subject<EditableIncidentText | null>();

  expandRow$ = this.expandRow.asObservable();
  editablePoint$ = this.editablePoint.asObservable();
  editableIncidentText$ = this.editableIncidentText.asObservable();

  protected vmState$ = merge(
    this.expandRow$.pipe(map((r) => ({ expandRow: r }))),
    this.editablePoint$.pipe(map((r) => ({ editablePoint: r }))),
    this.editableIncidentText$.pipe(map((r) => ({ editableIncidentText: r })))
  ).pipe(
    startWith({
      expandRow: null,
      editablePoint: null,
      editableIncidentText: null,
    }),
    scan((prev, next) => {
      const [name, value] = Object.entries(next)[0];
      return {
        ...prev,
        [name]: value,
      };
    }),
    filter((a): a is IncidentConfigVm => !!a),
    shareReplay({ refCount: true })
  );

  vm$ = combineLatest([this.vmState$, this.stateService.vm$]).pipe(
    map(([vmState, vm]) => ({
      ...vmState,
      ...vm,
    }))
  );

  trackBy(index: number, item: EventIncidentType | IEventIncidentTypeText) {
    return item.id;
  }

  onExpandChange(id: number, checked: boolean) {
    this.expandRow.next(checked ? id : null);
  }

  startEdit(
    item: EventIncidentType,
    i: number,
    pintItem: number | boolean | string
  ) {
    if (pintItem === false) {
      return;
    }
    this.editablePoint.next({
      idIncident: item.id,
      indexPoint: i,
      value: isNaN(parseInt(`${pintItem}`, 10))
        ? 0
        : parseInt(`${pintItem}`, 10),
    });
  }

  stopEdit(editablePoint: EditablePoint, incidentType: EventIncidentType) {
    const { indexPoint, value } = editablePoint;
    const { eventPoint } = incidentType;
    const pointInst = eventPoint || new EventIncidentTypePoint();
    pointInst.incidentTypeId = incidentType.id;
    const positionArra: IndexToPoint['type'][] = [
      PositionEnum.Goalkeeper,
      PositionEnum.Defence,
      PositionEnum.Midfield,
      PositionEnum.Forward,
    ];
    const mapPoint = positionArra.reduce<IndexToPoint[]>((acum, item) => {
      const tmp: IndexToPoint[] = [
        {
          player: 'player1',
          type: item,
        },
        {
          player: 'player2',
          type: item,
        },
        {
          player: 'team',
          type: item,
        },
      ];
      acum.push(...tmp);
      return acum;
    }, []);
    const item = pointInst[mapPoint[indexPoint].player] || {
      Goalkeeper: 0,
      Forward: 0,
      Midfield: 0,
      Defence: 0,
    };
    item[mapPoint[indexPoint].type] = value;
    pointInst[mapPoint[indexPoint].player] = item;
    this.saveIncidentPoint.next(pointInst);
    this.editablePoint.next(null);
  }

  startEditIncident($event: MouseEvent, item: EventIncidentType) {
    this.editableIncidentText.next({
      idIncident: item.id,
      value: item.describe,
    });
    const parent = this.renderer.parentNode($event.target);
    setTimeout(() => {
      parent.firstElementChild.focus();
    });
  }

  stopIncidentEdit(editableIncidentText: EditableIncidentText) {
    this.editableIncidentText.next(null);
    const temp = this.stateService.query(
      getEntity(editableIncidentText.idIncident, { ref: incidentEntitiesRef })
    );
    if (!temp) {
      return;
    }
    temp.describe = editableIncidentText.value;
    this.saveIncident.next(temp);
  }

  ngOnDestroy() {
    this.stateService.destroy();
  }
}
