<ng-container *rxLet="vm$; let vm">
  <nz-table
    appResizeTable
    [nzBordered]="true"
    [nzData]="vm.accountList"
    [nzLoading]="vm.isLoadingAccount"
    [nzTotal]="vm.pageInfo.countAll"
    [nzPageSize]="vm.pageInfo.pageSize"
    [nzPageIndex]="vm.pageInfo.page"
    [nzFrontPagination]="false"
    (nzQueryParams)="onQueryParamsChange($event)"
    [nzTitle]="TitleTable"
    nzSize="small"
  >
    <thead>
    <tr>
      <th [nzWidth]="'50px'">ID</th>
      <th [nzWidth]="'100px'">Type</th>
      <th>Amount</th>
      <th>Hold</th>
      <th>Details</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let account of vm.accountList; trackBy: trackBy">
      <td>{{ account.id }}</td>
      <td>{{ account.amountType}}</td>
      <td>{{ account.amountValue | currency: account.amountCurrency }}</td>
      <td>{{ account.holdValue | currency: account.amountCurrency }}</td>
      <td>{{ account.details }}</td>
      <td>
        <button
          *ngIf="account.amountType !== 'User'"
          [routerLink]="['./', { outlets: { 'modal': ['edit', account.id] }}]"
          nz-button
          nzType="primary"
        >
          Edit account
        </button>
        <button
          *ngIf="account.amountType === 'User'"
          nz-button
          (click)="resetPutMoneyForm(account.id)"
          (nzOnConfirm)="putMoney()"
          nzType="primary"
          nz-popconfirm
          [nzPopconfirmTitle]="popConfirmTitle"
          nzPopconfirmPlacement="bottom"
          [nzBeforeConfirm]="beforeConfirm"
        >
          Put Money
        </button>
      </td>
    </tr>
    </tbody>
  </nz-table>
  <ng-template #TitleTable>
    <div nz-row>
      <div nz-col nzSpan="4">
        Account list
      </div>
      <div nz-col nzSpan="20">
        <button nz-button nzType="primary" [routerLink]="['./', { outlets: { 'modal': ['creat'] }}]">Add account</button>
      </div>
    </div>
  </ng-template>

  <ng-template #modalTitleTpl>
    {{vm.modalTitle}}
  </ng-template>
  <nz-modal
    [nzVisible]="vm.showModal"
    [nzTitle]="modalTitleTpl"
    [nzFooter]="modalFooter"
    (nzOnCancel)="hideModal()"
  >
    <ng-container *nzModalContent>
      <nz-spin nzTip="Loading..." [nzSpinning]="vm.isLoadingAccount">
        <router-outlet *ngIf="!vm.isLoadingAccount" name="modal" (activate)="activateOutlet($event)"></router-outlet>
      </nz-spin>
    </ng-container>
    <ng-template #modalFooter>
      <button nz-button nzType="default" (click)="hideModal()">Cancel</button>
      <button nz-button nzType="primary" (click)="handleOk()" [nzLoading]="vm.isLoadingModal">Ok</button>
    </ng-template>
  </nz-modal>
  <ng-template #popConfirmTitle>
    <form nz-form [formGroup]="putMoneyForm">
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="The input is required">
          <nz-select
            nzPlaceHolder="From account"
            nzId="type"
            [formControl]="putMoneyForm.controls.fromAccountId"
          >
            <nz-option *ngFor="let item of vm.listMockAccount" [nzLabel]="item.details"
                       [nzValue]="item.id"></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzSm]="24" [nzXs]="24" nzErrorTip="The input is required">
          <nz-input-number
            [formControl]="putMoneyForm.controls.amountValue"
            [nzStep]="1" [nzPlaceHolder]="'Sum of amount'" nzMin="1"  />
        </nz-form-control>
      </nz-form-item>
    </form>
  </ng-template>
</ng-container>


