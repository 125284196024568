import { BillingAccount } from '@matchman/database/entities';
import { entitiesPropsFactory } from '@ngneat/elf-entities';
import { createState, withProps } from '@ngneat/elf';

import { FetchStatus } from '../../../types';
import { defaultStatus } from '../../../utils';

import * as observeEffect from './observe';

const name = 'BillingAccountListState';

export const { withAccountListEntities, accountListEntitiesRef } =
  entitiesPropsFactory('accountList');

type BillingAccountListStateProps = {
  accountListStatus: FetchStatus;
  pageInfo: {
    countAll: number;
    pageSize: number;
    page: number;
  };
  savingInModal: boolean;
  puttingMoney: boolean;
};

const { state, config } = createState(
  withAccountListEntities<BillingAccount>(),
  withProps<BillingAccountListStateProps>({
    accountListStatus: defaultStatus(),
    pageInfo: {
      page: 1,
      countAll: 0,
      pageSize: 100,
    },
    savingInModal: false,
    puttingMoney: false,
  })
);

export const options = { state, config, name };

export type BillingAccountListState = typeof state;

export { observeEffect };
