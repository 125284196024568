import { Pipe, PipeTransform } from '@angular/core';
import { EventIncidentType } from '@matchman/database/entities';

type player1 = number | string | boolean;
type player2 = number | string | boolean;
type team = number | string | boolean;

type PointArray = [player1, player2, team];

@Pipe({
  name: 'incidentPointToArray',
  standalone: true,
})
export class IncidentPointToArrayPipe implements PipeTransform {
  transform(
    value: EventIncidentType
  ): [...PointArray, ...PointArray, ...PointArray, ...PointArray] {
    const defaultPoint = [
      value.player1 ? '-' : false,
      value.player2 ? '-' : false,
      value.team ? '-' : false,
    ] as PointArray;
    const gk = [...defaultPoint] as PointArray;
    const df = [...defaultPoint] as PointArray;
    const ml = [...defaultPoint] as PointArray;
    const fr = [...defaultPoint] as PointArray;
    const { eventPoint } = value;

    if (!eventPoint) {
      return [...gk, ...df, ...ml, ...fr];
    }

    const {
      Goalkeeper: player1Goalkeeper,
      Defence: player1Defence,
      Midfield: player1Midfield,
      Forward: player1Forward,
    } = eventPoint.player1;

    const {
      Goalkeeper: player2Goalkeeper,
      Defence: player2Defence,
      Midfield: player2Midfield,
      Forward: player2Forward,
    } = eventPoint.player2;

    const {
      Goalkeeper: teamGoalkeeper,
      Defence: teamDefence,
      Midfield: teamMidfield,
      Forward: teamForward,
    } = eventPoint.team;

    gk[0] = player1Goalkeeper || gk[0];
    gk[1] = player2Goalkeeper || gk[1];
    gk[2] = teamGoalkeeper || gk[2];

    df[0] = player1Defence || df[0];
    df[1] = player2Defence || df[1];
    df[2] = teamDefence || df[2];

    ml[0] = player1Midfield || ml[0];
    ml[1] = player2Midfield || ml[1];
    ml[2] = teamMidfield || ml[2];

    fr[0] = player1Forward || fr[0];
    fr[1] = player2Forward || fr[1];
    fr[2] = teamForward || fr[2];

    return [...gk, ...df, ...ml, ...fr];
  }
}
