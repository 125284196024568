import {
  ChangeDetectionStrategy,
  Component,
  inject,
  signal,
} from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { CommonModule } from '@angular/common';
import { provideStore } from '@matchman/common-front';
import { BillingAccount } from '@matchman/database/entities';
import { RxLet } from '@rx-angular/template/let';
import { NzTableModule, NzTableQueryParams } from 'ng-zorro-antd/table';

import { BillingAccountListState, observeEffect, options } from './state';
import { StateService } from './service/state.service';
import { ResizeTableDirective } from '../../directives/resize-table.directive';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { HeaderComponent } from '../../components/header/header.component';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzModalModule } from 'ng-zorro-antd/modal';
import {
  BehaviorSubject,
  distinctUntilChanged,
  filter,
  Observable,
  of,
  pairwise,
  startWith,
  switchMap,
  take,
  tap,
} from 'rxjs';

import { NzSpinModule } from 'ng-zorro-antd/spin';

import { AccountFormComponent } from '../../components/account-form/account-form.component';
import { NzPopconfirmModule } from 'ng-zorro-antd/popconfirm';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSelectModule } from 'ng-zorro-antd/select';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { Form } from '../../types';
import { map } from 'rxjs/operators';
import { select } from '@ngneat/elf';

export type SumData = {
  fromAccountId: number | null;
  toAccountId: number | null;
  amountValue: number | null;
};

export type SumDataForm = Form<SumData>;

@Component({
  selector: 'app-biling-acount-list',
  standalone: true,
  imports: [
    CommonModule,
    RxLet,
    NzTableModule,
    ResizeTableDirective,
    NzGridModule,
    HeaderComponent,
    NzButtonModule,
    NzModalModule,
    RouterOutlet,
    RouterLink,
    NzSpinModule,
    NzPopconfirmModule,
    NzFormModule,
    NzInputModule,
    NzSelectModule,
    ReactiveFormsModule,
    NzInputNumberModule,
  ],
  templateUrl: './billing-account-list.component.html',
  styleUrls: ['./billing-account-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ...provideStore<BillingAccountListState>(
      StateService,
      options,
      Object.values(observeEffect)
    ),
  ],
})
export class BillingAccountListComponent {
  protected router = inject(Router);
  protected activatedRoute = inject(ActivatedRoute);
  protected stateService = inject(StateService);
  protected subjectFormComponent =
    new BehaviorSubject<null | AccountFormComponent>(null);

  putMoneyForm = new FormGroup<SumDataForm>({
    fromAccountId: new FormControl(null, [Validators.required]),
    toAccountId: new FormControl(null, [Validators.required]),
    amountValue: new FormControl(null, [
      Validators.required,
      Validators.min(1),
    ]),
  });

  vm$ = this.stateService.vm$;

  onQueryParamsChange(params: NzTableQueryParams) {
    const { page } = this.activatedRoute.snapshot.queryParams;
    if (!page && params.pageIndex === 1) {
      return;
    }
    this.router.navigate([], {
      queryParams: { page: params.pageIndex },
      queryParamsHandling: 'merge',
    });
  }

  trackBy(index: number, item: BillingAccount): number {
    return item.id;
  }

  handleOk() {
    const component = this.subjectFormComponent.getValue();
    const formData = component.formData;
    this.stateService.saveAccount(formData);
  }

  hideModal() {
    this.router.navigate(['billing', { outlets: { modal: null } }]);
  }

  activateOutlet($event: AccountFormComponent) {
    const id = parseInt(
      this.activatedRoute.firstChild.snapshot.params['id'],
      10
    );
    const account = this.stateService.getAccount(id);
    this.subjectFormComponent.next($event);
    if (!account) return;
    $event.account = account;
  }

  resetPutMoneyForm(accountId: number) {
    this.putMoneyForm.reset();
    this.putMoneyForm.patchValue({
      toAccountId: accountId,
    });
  }
  beforeConfirm = () => {
    if (!this.putMoneyForm.valid) {
      this.putMoneyForm.updateValueAndValidity();
      return false;
    }
    return of(false).pipe(
      switchMap((r) =>
        this.stateService.pipe(
          select((r) => r.puttingMoney),
          distinctUntilChanged()
        )
      ),
      startWith(false),
      pairwise(),
      tap(([current, prev]) => {
        if (current === false && prev === false) {
          this.stateService.putMoney(this.putMoneyForm.getRawValue());
        }
      }),
      tap((r) => console.log(r)),
      filter(([current, prev]) => current === true && prev === false),
      map(() => true)
    );
  };

  putMoney() {
    // this.stateService.putMoney(this.putMoneyForm.getRawValue());
  }
}
