import { Route } from '@angular/router';

export const configRoutes: Route[] = [
  {
    path: 'incident',
    loadComponent: () =>
      import('../smart-components/incident-list/incident-list.component').then(
        (r) => r.IncidentListComponent
      ),
    data: {
      breadcrumb: 'Incident List',
    },
  },
  {
    path: 'demo-event',
    loadComponent: () =>
      import('../smart-components/demo-event/demo-event.component').then(
        (r) => r.DemoEventComponent
      ),
    data: {
      breadcrumb: 'Demo event',
    },
  },
];
