import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideStore } from '@matchman/common-front';
import { DemoTemplatesEvent } from '@matchman/database/entities';
import { RxLet } from '@rx-angular/template/let';
import { NzTableModule } from 'ng-zorro-antd/table';

import { observeEffect, options, DemoEventState } from './state';
import { StateService } from './service/state.service';
import { ResizeTableDirective } from '../../directives/resize-table.directive';
import { ShowDayOfWeekPipe } from './pipes/show-day-of-week.pipe';
import { NzTagModule } from 'ng-zorro-antd/tag';
import { ShowTimePipe } from './pipes/show-time.pipe';
import { NzButtonModule } from 'ng-zorro-antd/button';

function assertDemoTemplatesEvent(
  item: DemoTemplatesEvent | string
): item is DemoTemplatesEvent {
  return !!item['id'];
}

@Component({
  selector: 'app-demo-event',
  standalone: true,
  imports: [
    CommonModule,
    NzTableModule,
    RxLet,
    ResizeTableDirective,
    ShowDayOfWeekPipe,
    NzTagModule,
    ShowTimePipe,
    NzButtonModule,
  ],
  templateUrl: './demo-event.component.html',
  styleUrls: ['./demo-event.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ...provideStore<DemoEventState>(
      StateService,
      options,
      Object.values(observeEffect)
    ),
  ],
})
export class DemoEventComponent implements OnDestroy {
  protected stateService = inject(StateService);

  vm$ = this.stateService.vm$;

  trackBy(index: number, item: DemoTemplatesEvent | string): number | string {
    if (assertDemoTemplatesEvent(item)) {
      return item.id;
    }
    return item;
  }

  ngOnDestroy(): void {
    this.stateService.destroy();
  }
}
