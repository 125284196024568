import { InjectionToken, Provider } from '@angular/core';
import { httpRpcCall } from './http-call.provider';
import {
  catchError,
  filter,
  from,
  map,
  Observable,
  of,
  switchMap,
  toArray,
} from 'rxjs';

import {
  RpcHttpCall,
  WrapperType,
  RpcRequest,
  RpcFuncBatch,
  RpcResponse,
  ReturnBatch,
} from '../types';

import { isErrorResponse, RpcError } from '../utils';

export const RPC_BATCH = new InjectionToken('rpc batch');

function isArray<T extends () => any>(
  data: RpcRequest<T> | RpcRequest<T>[]
): data is RpcRequest<T> {
  return !Array.isArray(data);
}

export const rpcBatchProvider: Provider = {
  provide: RPC_BATCH,
  useFactory: (httpRpcCall: RpcHttpCall<any>): RpcFuncBatch => {
    return <T extends WrapperType[]>(...arg: T): Observable<ReturnBatch<T>> => {
      const rpcData = arg.map((item, index) => {
        return {
          jsonrpc: '2.0',
          params: item.rpcData.arg,
          method: `${item.rpcData.nameSpace}.${item.rpcData.method}`,
          id: index,
        } as RpcRequest<typeof item.rpcData.arg>;
      });
      const r = httpRpcCall(rpcData).pipe(
        map((i) => {
          if (!Array.isArray(i) && isErrorResponse(i)) {
            throw new RpcError(i.error.code, i.error.message);
          }

          return i;
        }),
        filter((i): i is RpcResponse<() => any>[] => Array.isArray(i)),
        switchMap((result) => from(result)),
        map((i) => {
          const inPut = rpcData.find((data) => data.id === i.id);
          if (isErrorResponse(i)) {
            return {
              outPut: new RpcError(
                i.error.code,
                i.error.message,
                i.error.detail
              ),
              inPut: (inPut || {}).params,
            };
          }
          return {
            outPut: i.result,
            inPut: (inPut || {}).params,
          };
        }),
        toArray(),
        catchError((error) => {
          let resultError = {};
          if (error instanceof RpcError) {
            resultError = rpcData.map((i) => ({
              outPut: error,
              inPut: i.params,
            }));
          }
          return of(resultError);
        })
      );

      return r as Observable<ReturnBatch<T>>;
    };
  },
  deps: [httpRpcCall],
};
