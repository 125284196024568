import {
  Column,
  Entity,
  OneToMany,
  PrimaryColumn,
  UpdateDateColumn,
} from 'typeorm';

import { TournamentTemplates, ITournamentTemplate } from './';

export type ISports = Sports;

@Entity('sports')
export class Sports {
  @PrimaryColumn()
  public id!: number;

  @Column()
  public name!: string;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;

  @OneToMany(() => TournamentTemplates, (item) => item.sport)
  public tournamentTemplate!: ITournamentTemplate[];
}
