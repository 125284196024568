import {
  Column,
  Entity,
  JoinColumn,
  ManyToOne,
  PrimaryColumn,
  RelationId,
  UpdateDateColumn,
} from 'typeorm';
import { EventIncidentType, IEventIncidentType } from './';

export type IEventIncidentTypeText = EventIncidentTypeText;

@Entity('event_incident_type_text')
export class EventIncidentTypeText {
  @PrimaryColumn()
  public id!: number;

  @RelationId((item: EventIncidentTypeText) => item.eventIncidentType)
  @Column({
    name: 'event_incident_typeFK',
  })
  eventIncidentTypeFK!: number;

  @ManyToOne(() => EventIncidentType, (item) => item.id)
  @JoinColumn({
    name: 'event_incident_typeFK',
  })
  eventIncidentType!: IEventIncidentType;

  @Column()
  public name!: string;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;
}
