import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
  Output,
  Input,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RxLet } from '@rx-angular/template/let';
import {
  TournamentTemplates,
  Tournaments,
  Teams,
} from '@matchman/database/entities';
import { provideStore } from '@matchman/common-front';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzSelectModule } from 'ng-zorro-antd/select';

import {
  EventSearchFormService,
  SearchFormValue,
} from './service/event-search-form.service';
import { SearchFormState, observeEffect, options } from './state';
import { StateService } from './service/state.service';
import { loadTournamentEffect } from './effect';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';

@Component({
  selector: 'app-event-search-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    NzFormModule,
    NzGridModule,
    NzSelectModule,
    ReactiveFormsModule,
    NzDatePickerModule,
    RxLet,
    NzCheckboxModule,
  ],
  templateUrl: './event-search-form.component.html',
  styleUrls: ['./event-search-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ...provideStore<SearchFormState>(
      StateService,
      options,
      Object.values(observeEffect)
    ),
    EventSearchFormService,
  ],
})
export class EventSearchFormComponent implements OnInit, OnDestroy {
  private eventSearchFormService = inject(EventSearchFormService);
  private searchFormState = inject(StateService);
  private loadTournament = inject(loadTournamentEffect);

  public eventSearchForm = this.eventSearchFormService.searchForm;

  public vm$ = this.searchFormState.vm$;

  @Output() valueChanges = this.eventSearchFormService.valueChanges$;
  @Input() set setSearchValue(value: SearchFormValue) {
    this.eventSearchForm.patchValue(value);
  }

  trackBy(
    index: number,
    item: TournamentTemplates | Tournaments | Teams
  ): number {
    return item.id;
  }

  ngOnInit() {
    this.loadTournament.next(void 0);
  }

  testChange($event: Event) {
    console.log($event);
  }

  ngOnDestroy() {
    this.searchFormState.destroy();
  }
}
