import { Column, Entity, PrimaryColumn, UpdateDateColumn } from 'typeorm';
import { EventStatusEnum } from '../types';

export type IStatusType = StatusType;

@Entity('status_type')
export class StatusType {
  @PrimaryColumn()
  public id!: number;

  @Column()
  public name!: string;

  @Column({
    name: 'status_type',
    type: 'varchar',
  })
  public statusType!: EventStatusEnum;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;
}
