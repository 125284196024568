import { Route } from '@angular/router';

export const eventsRoutes: Route[] = [
  {
    path: '',
    pathMatch: 'full',
    loadComponent: () =>
      import('../smart-components/events-list/events-list.component').then(
        (r) => r.EventsListComponent
      ),
  },
  {
    path: ':id',
    loadComponent: () =>
      import('../smart-components/event/event.component').then(
        (r) => r.EventComponent
      ),
    data: {
      breadcrumb: 'Event',
    },
    children: [
      {
        path: 'line-up',
        data: {
          breadcrumb: 'Line Up',
        },
        loadComponent: () =>
          import(
            '../smart-components/line-up-for-event/line-up-for-event.component'
          ).then((r) => r.LineUpForEventComponent),
        children: [
          {
            path: 'incident-player/:id',
            outlet: 'modal',
            loadComponent: () =>
              import(
                '../smart-components/show-incident/show-incident.component'
              ).then((r) => r.ShowIncidentComponent),
          },
        ],
      },
    ],
  },
];
