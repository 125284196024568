import { ObserveFunc } from '@matchman/common-front';
import { inject } from '@angular/core';
import { Tournaments } from '@matchman/database/entities';
import { setEntities } from '@ngneat/elf-entities';
import { setProp } from '@ngneat/elf';
import { endWithAsyncPipe } from '@matchman/frontend-utils';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  of,
  startWith,
  switchMap,
} from 'rxjs';
import { JsonApiSdkService } from 'json-api-nestjs-sdk';

import { SearchFormState, tournamentsEntitiesRef } from '../index';
import { EventSearchFormService } from '../../service/event-search-form.service';
import { StateService } from '../../service/state.service';

export function loadTournament(
  this: StateService
): ObserveFunc<SearchFormState> {
  const eventSearchFormService = inject(EventSearchFormService);
  const jsonApiUtilsService = inject(JsonApiSdkService);

  const loadTournament$ = (tournamentTemplateFK) =>
    jsonApiUtilsService
      .getAll(Tournaments, {
        filter: {
          target: {
            tournamentTemplateFK: {
              eq: tournamentTemplateFK,
            },
          },
        },
        page: {
          size: 200,
          number: 1,
        },
      })
      .pipe(
        map((r) =>
          r.map((i) => {
            i.id = parseInt(`${i.id}`, 10);
            return i;
          })
        ),
        map((r) =>
          this.reduceForUpdate(setEntities(r, { ref: tournamentsEntitiesRef }))
        ),
        startWith(
          this.reduceForUpdate(
            setProp('statusTournament', (state) => ({
              ...state,
              ...{
                isLoading: true,
                status: 'idle' as typeof state['status'],
              },
            }))
          )
        ),
        endWithAsyncPipe(
          this.reduceForUpdate(
            setProp('statusTournament', (state) => ({
              ...state,
              ...{
                isLoading: false,
                isSuccess: true,
                status: 'done' as typeof state['status'],
              },
            }))
          )
        ),
        catchError((err) => {
          return of(
            this.reduceForUpdate(
              setProp('statusTournament', (state) => ({
                ...state,
                ...{
                  isLoading: false,
                  isSuccess: false,
                  isError: true,
                  status: 'done' as typeof state['status'],
                  error: err,
                },
              }))
            )
          );
        })
      );

  return eventSearchFormService.searchForm.valueChanges.pipe(
    map((value) => value.tournamentTemplates),
    distinctUntilChanged(),
    switchMap((tournamentTemplateFK) =>
      tournamentTemplateFK
        ? loadTournament$(tournamentTemplateFK)
        : of(
            this.reduceForUpdate(
              setEntities([], { ref: tournamentsEntitiesRef })
            )
          )
    )
  );
}
