import { distinctUntilChanged, map, OperatorFunction, pipe } from 'rxjs';
import { Params } from '@angular/router';
import { parse } from 'date-fns';
import { SearchFormValue } from '../../smart-components/event-search-form/service/event-search-form.service';
import { dateForm } from '../../constant/inex';

export function getEventFilter(): OperatorFunction<Params, SearchFormValue> {
  const keyOfName = [
    'endDate',
    'startDate',
    'tournamentTemplates',
    'tournament',
    'homeTeam',
    'awayTeam',
    'showDemo',
  ];

  return pipe(
    map((r) => {
      return Object.entries(r).reduce<SearchFormValue>(
        (acum, [key, value]) => {
          const keyName = key.replace('filter[', '').replace(']', '');
          if (keyOfName.includes(keyName)) {
            if (keyName === 'startDate' || keyName === 'endDate') {
              if (keyName === 'startDate') {
                acum['eventDate'][0] = parse(value, dateForm, new Date());
              }
              if (keyName === 'endDate') {
                acum['eventDate'][1] = parse(value, dateForm, new Date());
              }
            } else if (keyName === 'showDemo') {
              acum[keyName] = value !== 'false';
            } else {
              acum[keyName] = parseInt(value);
            }
          }
          return acum;
        },
        {
          eventDate: [],
          awayTeam: null,
          homeTeam: null,
          tournamentTemplates: null,
          tournament: null,
          showDemo: false,
        }
      );
    }),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b))
  );
}
