import { importProvidersFrom } from '@angular/core';
import { AuthHttpInterceptor, AuthModule } from '@auth0/auth0-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ConfigApplication } from '../types';

export default (config: ConfigApplication) => [
  importProvidersFrom(
    AuthModule.forRoot({
      domain: config.auth.domain,
      clientId: config.auth.clientId,
      authorizationParams: {
        redirect_uri: `${window.location.origin}/callback`,
        audience: config.auth.audience,
        scope: 'offline_access',
      },
      cacheLocation: 'localstorage',
      httpInterceptor: {
        allowedList: [
          {
            uri: `${config.apiUrl}api/v1/*`,
            allowAnonymous: true,
          },
        ],
      },
    })
  ),

  { provide: HTTP_INTERCEPTORS, useClass: AuthHttpInterceptor, multi: true },
];
