import { ModuleWithProviders, NgModule } from '@angular/core';
import { RpcConfig } from './types';
import {
  RPC_CONFIG,
  rpcProvider,
  rpcBatchProvider,
  httpCallProvider,
} from './provider';

@NgModule({})
export class JsonRpcModule {
  static forRoot(config: RpcConfig): ModuleWithProviders<JsonRpcModule> {
    return {
      ngModule: JsonRpcModule,
      providers: [
        rpcProvider,
        rpcBatchProvider,
        httpCallProvider,
        {
          provide: RPC_CONFIG,
          useValue: config,
        },
      ],
    };
  }
}
