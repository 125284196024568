import { Route } from '@angular/router';

export const billingRoutes: Route[] = [
  {
    path: '',
    loadComponent: () =>
      import(
        '../smart-components/billing-account-list/billing-account-list.component'
      ).then((r) => r.BillingAccountListComponent),
    children: [
      {
        path: 'edit/:id',
        outlet: 'modal',
        loadComponent: () =>
          import('../components/account-form/account-form.component').then(
            (r) => r.AccountFormComponent
          ),
      },
      {
        path: 'creat',
        outlet: 'modal',
        loadComponent: () =>
          import('../components/account-form/account-form.component').then(
            (r) => r.AccountFormComponent
          ),
      },
    ],
  },
];
