import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { provideStore } from '@matchman/common-front';

import { LineUpState, observeEffect, options } from './state';
import { StateService } from './service/state.service';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzTableModule } from 'ng-zorro-antd/table';
import {
  ActivatedRoute,
  Router,
  RouterLink,
  RouterOutlet,
} from '@angular/router';
import { RxLet } from '@rx-angular/template/let';
import { NzGridModule } from 'ng-zorro-antd/grid';
import { EventsLineUp } from '@matchman/database/entities';
import { ResizeTableDirective } from '../../directives/resize-table.directive';

@Component({
  selector: 'app-line-up-for-event',
  standalone: true,
  imports: [
    CommonModule,
    NzPageHeaderModule,
    NzSpinModule,
    NzTableModule,
    RouterLink,
    RxLet,
    NzGridModule,
    ResizeTableDirective,
    RouterOutlet,
  ],
  templateUrl: './line-up-for-event.component.html',
  styleUrls: ['./line-up-for-event.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ...provideStore<LineUpState>(
      StateService,
      options,
      Object.values(observeEffect)
    ),
  ],
})
export class LineUpForEventComponent implements OnDestroy {
  protected lineUpStateService = inject(StateService);
  protected router = inject(Router);
  protected route = inject(ActivatedRoute);

  vm$ = this.lineUpStateService.vm$;

  trackBy(index: number, item: EventsLineUp): number {
    return item.id;
  }
  onBack() {
    this.router.navigate(['../..'], { relativeTo: this.route });
  }

  ngOnDestroy() {
    this.lineUpStateService.destroy();
  }
}
