import { inject, Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { distinctUntilChanged, filter, map, Observable, tap } from 'rxjs';

import { Form } from '../../../types';

export type SearchFormValue = {
  tournament: number | null;
  tournamentTemplates: number | null;
  homeTeam: number | null;
  awayTeam: number | null;
  eventDate: Date[];
  showDemo: boolean;
};

type SearchForm = Form<SearchFormValue>;

export type SearchValue = Partial<{
  tournament: number;
  tournamentTemplates: number;
  homeTeam: number;
  awayTeam: number;
  startDate: Date;
  endDate: Date;
}>;

@Injectable({
  providedIn: 'root',
})
export class EventSearchFormService {
  private fb = inject(FormBuilder);

  searchForm = new FormGroup<SearchForm>({
    tournament: new FormControl(null),
    tournamentTemplates: new FormControl(null),
    homeTeam: new FormControl(null),
    awayTeam: new FormControl(null),
    eventDate: new FormControl([]),
    showDemo: new FormControl(false),
  });

  valueChanges$: Observable<SearchValue> = this.searchForm.valueChanges.pipe(
    filter(() => this.searchForm.dirty),
    distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)),
    filter((value) => {
      const tmp = { ...value };
      if (!value.tournamentTemplates) {
        tmp.tournament = null;
        tmp.awayTeam = null;
        tmp.homeTeam = null;
      }
      if (!value.tournament) {
        tmp.awayTeam = null;
        tmp.homeTeam = null;
      }
      if (JSON.stringify(value) !== JSON.stringify(tmp)) {
        this.searchForm.patchValue(tmp);
      }
      return JSON.stringify(value) === JSON.stringify(tmp);
    }),
    map((value) => {
      const result = (
        Object.keys(value) as Array<keyof typeof value>
      ).reduce<SearchValue>((acum, key) => {
        if (key === 'eventDate') {
          return acum;
        }
        acum[key] = value[key];
        return acum;
      }, {});

      if (value['eventDate'] && value['eventDate'][0]) {
        result['startDate'] = value['eventDate'][0];
      }

      if (value['eventDate'] && value['eventDate'][1]) {
        result['endDate'] = value['eventDate'][1];
      }

      return result;
    })
  );
}
