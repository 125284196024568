import { inject, Injectable } from '@angular/core';
import { OnStoreInit, StoreService } from '@matchman/common-front';
import { select } from '@ngneat/elf';
import { selectAllEntities } from '@ngneat/elf-entities';
import { EventsLineUp } from '@matchman/database/entities';
import { combineLatestWith, map } from 'rxjs/operators';
import { shareReplay, combineLatest, startWith, filter } from 'rxjs';
import { LineUpState, lineUpEntitiesRef } from '../state';
import { sortPlayer } from '../../../utils';
import { loadEventIncidentTypeEffect } from '../effect';

@Injectable()
export class StateService
  extends StoreService<LineUpState>
  implements OnStoreInit
{
  protected loadEventIncidentType = inject(loadEventIncidentTypeEffect);

  protected event$ = this.pipe(
    select((state) => state.event),
    shareReplay({ refCount: true })
  );

  protected lineUpStatus$ = this.pipe(
    select((state) => state.statusLineUp),
    shareReplay({ refCount: true })
  );

  protected eventStatus$ = this.pipe(
    select((state) => state.statusEvents),
    shareReplay({ refCount: true })
  );

  protected lineUpHome$ = this.pipe(
    selectAllEntities({ ref: lineUpEntitiesRef }),
    combineLatestWith(this.event$),
    filter(([lineUp, events]) => !!events),
    map((r) => {
      const [lineUp, events] = r;
      return lineUp.filter((i) => i.teamId === events.homeTeamId);
    }),
    sortPlayer(),
    startWith([] as EventsLineUp[]),
    shareReplay({ refCount: true })
  );

  protected lineUpAway$ = this.pipe(
    selectAllEntities({ ref: lineUpEntitiesRef }),
    combineLatestWith(this.event$),
    filter(([lineUp, events]) => !!events),
    map((r) => {
      const [lineUp, events] = r;
      return lineUp.filter((i) => i.teamId === events.awayTeamId);
    }),
    sortPlayer(),
    startWith([] as EventsLineUp[]),
    shareReplay({ refCount: true })
  );

  vm$ = combineLatest([
    this.event$,
    this.lineUpStatus$,
    this.lineUpHome$,
    this.lineUpAway$,
    this.eventStatus$,
  ]).pipe(
    map(([event, lineUpStatus, lineUpHome, lineUpAway, eventStatus]) => ({
      event,
      lineUpStatus,
      lineUpHome,
      lineUpAway,
      eventStatus,
    }))
  );

  onStoreInit(): void {
    this.loadEventIncidentType.next(void 0);
  }
}
