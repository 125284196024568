import {
  Column,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToOne,
  PrimaryColumn,
  RelationId,
  UpdateDateColumn,
} from 'typeorm';
import { IPlayers, Players, EventsLineUp, IEventsLineUp } from './';

export type IPlayersEmaHistory = PlayersEmaHistory;

@Entity('players_ema_history')
export class PlayersEmaHistory {
  @PrimaryColumn()
  public id!: number;

  @RelationId((item: PlayersEmaHistory) => item.player)
  @Column({
    name: 'player_id',
  })
  public playerId!: number;

  @ManyToOne(() => Players, (item) => item.id)
  @JoinColumn({
    name: 'player_id',
  })
  public player!: IPlayers;

  @RelationId((item: PlayersEmaHistory) => item.lineUp)
  @Column({
    name: 'line_up_id',
  })
  public lineUpId!: number;

  @OneToOne(() => EventsLineUp, (item) => item.id)
  @JoinColumn({
    name: 'line_up_id',
  })
  public lineUp!: IEventsLineUp;

  @Column()
  public order!: number;

  @Column({
    name: 'sma_point',
  })
  public smaPoint!: number;

  @Column({
    name: 'real_point',
  })
  public realPoint!: number;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;
}
