import {
  Column,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  RelationId,
  UpdateDateColumn,
} from 'typeorm';
import {
  BillingAccount,
  IBillingAccount,
  JoinUserContest,
  IJoinUserContest,
} from './';
import { Currency, MetaType } from '../types';

export type IBillingHold = BillingHold;

@Entity('billing_hold')
export class BillingHold {
  @PrimaryGeneratedColumn()
  public id!: number;

  @RelationId((item: BillingHold) => item.hold)
  @Column({
    name: 'hold_id',
  })
  public holdId!: number | null;

  @ManyToOne(() => BillingHold, (item) => item.holds)
  @JoinColumn({
    name: 'hold_id',
    referencedColumnName: 'id',
  })
  public hold!: IBillingHold;

  @OneToMany(() => BillingHold, (item) => item.account)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'hold_id',
  })
  holds!: IBillingHold[];

  @RelationId((item: BillingHold) => item.account)
  @Column({
    name: 'account_id',
  })
  public accountId!: number;

  @ManyToOne(() => BillingAccount, (item) => item.holds)
  @JoinColumn({
    name: 'account_id',
    referencedColumnName: 'id',
  })
  public account!: IBillingAccount;

  @OneToOne(() => JoinUserContest, (item) => item.hold)
  public joinUserContest!: IJoinUserContest | null;

  @Column({
    name: 'amount_currency',
    enum: Currency,
    type: 'enum',
  })
  public amountCurrency!: Currency;

  @Column({
    name: 'amount_value',
  })
  public amountValue!: number;

  @Column()
  public details!: string;

  @Column({
    type: 'json',
  })
  public meta!: MetaType;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;
}
