import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';
import { EventIncidentType } from '@matchman/database/entities';

import { IncidentListComponent } from '../incident-list.component';

export const saveIncidentEffect = new InjectionToken<
  Subject<EventIncidentType>
>('saveIncidentEffect', {
  providedIn: IncidentListComponent,
  factory: () => new Subject(),
});
