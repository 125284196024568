import {
  Column,
  Entity,
  JoinColumn,
  OneToMany,
  OneToOne,
  PrimaryGeneratedColumn,
  RelationId,
  UpdateDateColumn,
} from 'typeorm';
import { AmountType, Currency, MetaType } from '../types';
import { IUsers, Users } from './users';
import { BillingTransaction, IBillingTransaction } from './billing-transaction';
import { BillingHold, IBillingHold } from './billing-hold';

export type IBillingAccount = BillingAccount;

@Entity('billing_account')
export class BillingAccount {
  @PrimaryGeneratedColumn()
  public id!: number;

  @RelationId((item: BillingAccount) => item.user)
  @Column({
    name: 'user_id',
  })
  public userId!: number | null;

  @OneToOne(() => Users, (item) => item.id)
  @JoinColumn({
    name: 'user_id',
    referencedColumnName: 'id',
  })
  public user!: IUsers;

  @Column({
    name: 'amount_type',
    enum: AmountType,
    type: 'enum',
  })
  public amountType!: AmountType;

  @Column({
    name: 'amount_currency',
    enum: Currency,
    type: 'enum',
  })
  public amountCurrency!: Currency;

  @Column({
    name: 'amount_value',
  })
  public amountValue!: number;

  @Column({
    name: 'hold_value',
  })
  public holdValue!: number;

  @Column()
  public details!: string;

  @Column({
    type: 'json',
  })
  public meta!: MetaType;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @OneToMany(() => BillingTransaction, (item) => item.fromAccount)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'from_account_id',
  })
  fromAccount!: IBillingTransaction[];

  @OneToMany(() => BillingTransaction, (item) => item.toAccount)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'to_account_id',
  })
  toAccount!: IBillingTransaction[];

  @OneToMany(() => BillingHold, (item) => item.account)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'account_id',
  })
  holds!: IBillingHold[];
}
