import { Entity, PrimaryColumn, Column, OneToOne, JoinColumn } from 'typeorm';

import { PointByPosition } from '../types';
import { EventIncidentType, IEventIncidentType } from './';
import { IsDefined } from 'class-validator';

export type IEventIncidentTypePoint = EventIncidentTypePoint;

@Entity('event_incident_type_point')
export class EventIncidentTypePoint {
  @PrimaryColumn({
    name: 'incident_type_id',
  })
  public incidentTypeId!: number;

  @IsDefined()
  @Column({
    type: 'json',
  })
  public player1!: PointByPosition;

  @IsDefined()
  @Column({
    type: 'json',
  })
  public player2!: PointByPosition;

  @IsDefined()
  @Column({
    type: 'json',
  })
  public team!: PointByPosition;

  @Column({
    name: 'is_current_team',
  })
  public isCurrentTeam!: boolean;

  @OneToOne(() => EventIncidentType, (item) => item.eventPoint)
  @JoinColumn({
    name: 'incident_type_id',
    referencedColumnName: 'id',
  })
  public incidentType!: IEventIncidentType;
}
