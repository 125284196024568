import {
  catchError,
  distinctUntilChanged,
  map,
  of,
  OperatorFunction,
  pipe,
} from 'rxjs';
import { ParamMap } from '@angular/router';
import { parse, format } from 'date-fns';

export function getDateFormQueryPipe(
  nameParam: string,
  formatDate = 'yyyy-MM-dd'
): OperatorFunction<ParamMap, Date> {
  return pipe(
    map((r) => r.get(nameParam)),
    map((dateString) =>
      dateString ? parse(dateString, formatDate, new Date()) : new Date()
    ),
    catchError(() => of(new Date())),
    map((date) => (date.toString() === 'Invalid Date' ? new Date() : date)),
    distinctUntilChanged(
      (prev, current) =>
        format(prev, formatDate) === format(current, formatDate)
    )
  );
}
