import { inject, Injectable } from '@angular/core';
import { OnStoreInit, StoreService } from '@matchman/common-front';
import { select } from '@ngneat/elf';
import { getEntity, selectAllEntities } from '@ngneat/elf-entities';
import { DemoTemplatesEvent, Events } from '@matchman/database/entities';
import { map } from 'rxjs/operators';
import { shareReplay, combineLatest, startWith } from 'rxjs';

import { loadDemoEventEffect } from '../effect';
import {
  DemoEventState,
  demoEventEntitiesRef,
  eventsEntitiesRef,
} from '../state';

@Injectable()
export class StateService
  extends StoreService<DemoEventState>
  implements OnStoreInit
{
  protected loadDemoEventEffect = inject(loadDemoEventEffect);

  protected demoEventList$ = this.pipe(
    selectAllEntities({ ref: demoEventEntitiesRef }),
    map((demoEvent) => {
      return demoEvent.map((i) => {
        i.id = parseInt(`${i.id}`, 10);
        i.events = this.query(getEntity(i.eventId, { ref: eventsEntitiesRef }));
        return i;
      });
    }),
    shareReplay({ refCount: true }),
    startWith([] as DemoTemplatesEvent[])
  );

  protected loading$ = this.pipe(
    select((state) => state.statusEventDemo.isLoading)
  );

  vm$ = combineLatest([this.demoEventList$, this.loading$]).pipe(
    map(([demoEventList, loading]) => ({ demoEventList, loading }))
  );

  onStoreInit(): void {
    this.loadDemoEventEffect.next(void 0);
  }
}
