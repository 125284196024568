<nz-modal
  [nzVisible]="vm.eventInfo && vm.playerInfo"
  [nzTitle]="modalTitle"
  nzCentered
  (nzOnCancel)="hideModal()"
  [nzFooter]="modalFooter"
  *rxLet="vm$; let vm"
>

  <ng-template #modalTitle>
    Point in "{{ vm.eventInfo.homeTeam.name }} - {{ vm.eventInfo.awayTeam.name }}" for
    player <span class="ant-page-header-heading-sub-title">
    <strong>{{ vm.playerInfo.name }}</strong> -
    <strong>{{ vm.playerInfo.positionName }}</strong>
  </span>
  </ng-template>

  <ng-container *nzModalContent>
    <div class="wrapper">
      <nz-list [nzLoading]="vm.loading || vm.loadingType">
        <nz-list-empty
          *ngIf="vm.pointList.length === 0 && !(vm.loading || vm.loadingType); else list"
          [nzNoResult]="emptyPoint"
        ></nz-list-empty>
        <ng-template #list>
          <nz-list-item *ngFor="let item of vm.pointList">
            <span nz-typography>{{item.text}}</span>
            {{ item.point }}
          </nz-list-item>
        </ng-template>
      </nz-list>
    </div>
  </ng-container>

  <ng-template #emptyPoint>
    <nz-empty
      nzNotFoundImage="simple"
      [nzNotFoundContent]="contentTpl"
      class="empty-after-move"
    >
      <ng-template #contentTpl>
        <p class="ant-empty-description">
          <strong>{{ vm.playerInfo.name }}</strong> does not have
          point in event
          <strong>
            {{ vm.eventInfo.homeTeam.name }} - {{ vm.eventInfo.awayTeam.name }}
          </strong>
        </p>
      </ng-template>
    </nz-empty>
  </ng-template>

  <ng-template #modalFooter>
    <button nz-button nzType="primary" (click)="hideModal()">Ok</button>
  </ng-template>
</nz-modal>

