<form nz-form [formGroup]="form">
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="type">Account Type</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is required">
      <nz-select
        nzPlaceHolder="Account type"
        nzId="type"
        [formControl]="form.controls.amountType"
      >
        <nz-option *ngFor="let item of amountType" [nzLabel]="item"
                   [nzValue]="item"></nz-option>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="details">Details</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is required">
      <input [formControl]="form.controls.details" nz-input placeholder="Details info">
    </nz-form-control>
  </nz-form-item>
  <nz-form-item>
    <nz-form-label [nzSm]="6" [nzXs]="24" nzRequired nzFor="details">Meta</nz-form-label>
    <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is required">
      <textarea [formControl]="form.controls.meta" nz-input placeholder="Meta info"></textarea>
    </nz-form-control>
  </nz-form-item>
</form>
