import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { provideStore } from '@matchman/common-front';
import { getNumberFromParamsPipe } from '@matchman/frontend-utils';
import { Events, Players } from '@matchman/database/entities';
import { selectEntity } from '@ngneat/elf-entities';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { map, switchMap, filter } from 'rxjs/operators';
import {
  distinctUntilChanged,
  startWith,
  combineLatest,
  shareReplay,
} from 'rxjs';

import { IncidentPlayerState, observeEffect, options } from './state';
import { StateService } from './service/state.service';
import { StateService as LineUpStateService } from '../line-up-for-event/service/state.service';
import { lineUpEntitiesRef } from '../line-up-for-event/state';
import { RxLet } from '@rx-angular/template/let';
import { select } from '@ngneat/elf';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzTypographyModule } from 'ng-zorro-antd/typography';
import { NzEmptyModule } from 'ng-zorro-antd/empty';

@Component({
  selector: 'app-show-incident',
  standalone: true,
  imports: [
    CommonModule,
    NzModalModule,
    NzButtonModule,
    RxLet,
    NzSpinModule,
    NzListModule,
    NzTypographyModule,
    NzEmptyModule,
  ],
  templateUrl: './show-incident.component.html',
  styleUrls: ['./show-incident.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ...provideStore<IncidentPlayerState>(
      StateService,
      options,
      Object.values(observeEffect)
    ),
  ],
})
export class ShowIncidentComponent implements OnDestroy {
  protected router = inject(Router);
  protected activatedRoute = inject(ActivatedRoute);
  protected stateService = inject(StateService);

  vm$ = this.stateService.vm$;

  hideModal() {
    const eventId =
      this.activatedRoute.snapshot.parent.params['id'] ||
      this.activatedRoute.snapshot.parent.parent.params['id'];

    this.router.navigate([
      'events',
      eventId,
      'line-up',
      { outlets: { modal: null } },
    ]);
  }

  ngOnDestroy() {
    this.stateService.destroy();
  }
}
