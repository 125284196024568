import { ObserveFunc } from '@matchman/common-front';
import { inject } from '@angular/core';
import { Teams, TournamentStages } from '@matchman/database/entities';
import { endWithAsyncPipe } from '@matchman/frontend-utils';
import { setProp } from '@ngneat/elf';
import { setEntities } from '@ngneat/elf-entities';
import {
  catchError,
  distinctUntilChanged,
  filter,
  map,
  of,
  startWith,
  switchMap,
} from 'rxjs';

import { QueryField } from 'json-api-nestjs';
import { JsonApiSdkService } from 'json-api-nestjs-sdk';

import { SearchFormState, teamsEntitiesRef } from '../index';
import { StateService } from '../../service/state.service';
import { EventSearchFormService } from '../../service/event-search-form.service';

export function loadTeams(this: StateService): ObserveFunc<SearchFormState> {
  const eventSearchFormService = inject(EventSearchFormService);
  const jsonApiUtilsService = inject(JsonApiSdkService);

  const loadTeam = (tournamentFK) => {
    return jsonApiUtilsService
      .getAll(TournamentStages, {
        [QueryField.filter]: {
          target: {
            tournamentFK: { eq: `${tournamentFK}` },
          },
        },
      })
      .pipe(
        map((r) => r.map((i) => `${i.id}`)),
        switchMap((ids) => {
          if (ids.length === 0) {
            return of([] as Teams[]);
          }
          return jsonApiUtilsService.getAll(Teams, {
            [QueryField.filter]: {
              relation: {
                tournamentStages: {
                  id: {
                    in: ids,
                  },
                },
              },
            },
            [QueryField.page]: {
              size: 100,
              number: 1,
            },
          });
        }),
        map((r) =>
          r.map((i) => {
            i.id = parseInt(`${i.id}`, 10);
            return i;
          })
        ),
        map((r) =>
          this.reduceForUpdate(setEntities(r, { ref: teamsEntitiesRef }))
        ),
        startWith(
          this.reduceForUpdate(
            setProp('statusTeam', (state) => ({
              ...state,
              ...{
                isLoading: true,
                status: 'idle' as typeof state['status'],
              },
            }))
          )
        ),
        endWithAsyncPipe(
          this.reduceForUpdate(
            setProp('statusTeam', (state) => ({
              ...state,
              ...{
                isLoading: false,
                isSuccess: true,
                status: 'done' as typeof state['status'],
              },
            }))
          )
        ),
        catchError((err) => {
          return of(
            this.reduceForUpdate(
              setProp('statusTeam', (state) => ({
                ...state,
                ...{
                  isLoading: false,
                  isSuccess: false,
                  isError: true,
                  status: 'done' as typeof state['status'],
                  error: err,
                },
              }))
            )
          );
        })
      );
  };

  return eventSearchFormService.searchForm.valueChanges.pipe(
    map((value) => value.tournament),
    distinctUntilChanged(),
    switchMap((tournamentFK) =>
      tournamentFK
        ? loadTeam(tournamentFK)
        : of(this.reduceForUpdate(setEntities([], { ref: teamsEntitiesRef })))
    )
  );
}
