import { Reducer } from '@ngneat/elf';
import { Observable } from 'rxjs';

import { StoreService } from '../service/store.service';

export interface OnStoreInit {
  onStoreInit: () => void;
}

export type ReducerResult<S> = Reducer<S>[] | undefined;

export type ObserveFunc<S extends StoreService['state']> = Observable<
  ReducerResult<S>
>;
