import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'emptyString',
  standalone: true,
})
export class EmptyStringPipe implements PipeTransform {
  transform(value: string | null | boolean | number, mask: string): string {
    return value ? `${value}` : mask;
  }
}
