import {
  Column,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToMany,
  PrimaryColumn,
  RelationId,
  UpdateDateColumn,
} from 'typeorm';

import {
  EventIncidentType,
  IEventIncidentType,
  Events,
  IEvents,
  EventIncidentsDetail,
  IEventIncidentDetail,
  IncidentHasPoint,
  IIncidentHasPoint,
} from './';

export type IEventIncidents = EventIncidents;

@Entity('event_incidents')
export class EventIncidents {
  @PrimaryColumn()
  public id!: number;

  @RelationId((item: EventIncidents) => item.eventIncidentType)
  @Column({
    name: 'event_incident_typeFK',
  })
  eventIncidentTypeFK!: number;

  @ManyToOne(() => EventIncidentType, (item) => item.id)
  @JoinColumn({
    name: 'event_incident_typeFK',
  })
  eventIncidentType!: IEventIncidentType;

  @RelationId((item: EventIncidents) => item.event)
  @Column({
    name: 'event_id',
  })
  eventId!: number;

  @ManyToOne(() => Events, (item) => item.id)
  @JoinColumn({
    name: 'event_id',
  })
  event!: IEvents;

  @Column()
  elapsed!: number;

  @Column({
    name: 'elapsed_plus',
  })
  elapsedPlus!: number;

  @Column({
    name: 'sortorder',
  })
  sortorder!: number;

  @Column()
  comment!: string;

  @Column({
    name: 'is_remove',
  })
  public isRemove!: boolean;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;

  @OneToMany(() => EventIncidentsDetail, (item) => item.eventIncident)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'event_incident_id',
  })
  public eventIncidentDetail!: IEventIncidentDetail[];

  @OneToMany(() => IncidentHasPoint, (item) => item.incident)
  public incidentPoint!: IIncidentHasPoint[];
}
