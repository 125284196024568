import { inject } from '@angular/core';
import { ObserveFunc } from '@matchman/common-front';
import { EventIncidentType } from '@matchman/database/entities';
import { getEntity, updateEntities } from '@ngneat/elf-entities';
import { JsonApiSdkService } from 'json-api-nestjs-sdk';

import { IncidentListState, incidentEntitiesRef } from '../index';
import { StateService } from '../../service/state.service';
import { saveIncidentEffect } from '../../effect';
import { filter, map, switchMap } from 'rxjs';

export function saveIncident(
  this: StateService
): ObserveFunc<IncidentListState> {
  const jsonApiUtilsService = inject(JsonApiSdkService);
  const saveIncident = inject(saveIncidentEffect);

  return saveIncident.asObservable().pipe(
    switchMap((r) => {
      const inst = new EventIncidentType();
      inst.id = r.id;
      inst.describe = r.describe;
      return jsonApiUtilsService.patchOne(inst);
    }),
    filter((r): r is EventIncidentType => !!r),
    map((r) => this.query(getEntity(r.id, { ref: incidentEntitiesRef }))),
    filter((r): r is EventIncidentType => !!r),
    map((r) =>
      this.reduceForUpdate(
        updateEntities(r.id, r, { ref: incidentEntitiesRef })
      )
    )
  );
}
