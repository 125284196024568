import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { EventsLineUp } from '@matchman/database/entities';
import { ObserveFunc } from '@matchman/common-front';
import { endWithAsyncPipe } from '@matchman/frontend-utils';
import { setProp } from '@ngneat/elf';
import { setEntities } from '@ngneat/elf-entities';

import { map, switchMap } from 'rxjs/operators';
import { FilterOperand } from 'json-api-nestjs/filter-operand';
import { catchError, filter, of, startWith } from 'rxjs';
import { JsonApiSdkService } from 'json-api-nestjs-sdk';
import { QueryField } from 'json-api-nestjs';

import { StateService } from '../../service/state.service';
import { LineUpState, lineUpEntitiesRef } from '../';

export function loadLineUp(this: StateService): ObserveFunc<LineUpState> {
  const activatedRoute = inject(ActivatedRoute);
  const jsonApiUtilsService = inject(JsonApiSdkService);

  const loadLineUp = (eventId: number) =>
    jsonApiUtilsService
      .getAll(EventsLineUp, {
        [QueryField.filter]: {
          target: {
            eventId: { [FilterOperand.eq]: `${eventId}` },
          },
        },
        [QueryField.include]: [
          'players',
          'position',
          'incidentPoint',
          'events',
        ],
        [QueryField.page]: {
          number: 1,
          size: 100,
        },
      })
      .pipe(
        map((r) =>
          r.map((i) => {
            i.id = parseInt(`${i.id}`, 10);
            return i;
          })
        ),
        map((lineUp) =>
          this.reduceForUpdate(setEntities(lineUp, { ref: lineUpEntitiesRef }))
        ),
        startWith(
          this.reduceForUpdate(
            setProp('statusLineUp', (state) => ({
              ...state,
              ...{
                isLoading: true,
                status: 'idle' as typeof state['status'],
              },
            }))
          )
        ),
        endWithAsyncPipe(
          this.reduceForUpdate(
            setProp('statusLineUp', (state) => ({
              ...state,
              ...{
                isLoading: false,
                isSuccess: true,
                status: 'done' as typeof state['status'],
              },
            }))
          )
        ),
        catchError((err) => {
          return of(
            this.reduceForUpdate(
              setProp('statusLineUp', (state) => ({
                ...state,
                ...{
                  isLoading: false,
                  isSuccess: false,
                  isError: true,
                  status: 'done' as typeof state['status'],
                  error: err,
                },
              }))
            )
          );
        })
      );
  return activatedRoute.parent.paramMap.pipe(
    map((r) => r.get('id')),
    filter((id) => !!id),
    map((id) => parseInt(id, 10)),
    switchMap((id) => loadLineUp(id))
  );
}
