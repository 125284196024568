import { entitiesPropsFactory } from '@ngneat/elf-entities';
import { createState, withProps } from '@ngneat/elf';
import { Events } from '@matchman/database/entities';

import { FetchStatus } from '../../../types';
import { defaultStatus } from '../../../utils';
import * as observeEffect from './observe';

const name = 'EventList';

export const { withEventsEntities, eventsEntitiesRef } =
  entitiesPropsFactory('events');

type EventsListStoreProps = {
  statusEvents: FetchStatus;
  pageInfo: {
    countAll: number;
    pageSize: number;
    page: number;
  };
};

const { state, config } = createState(
  withEventsEntities<Events>(),
  withProps<EventsListStoreProps>({
    statusEvents: defaultStatus(),
    pageInfo: {
      page: 1,
      countAll: 0,
      pageSize: 0,
    },
  })
);

export const options = { state, config, name };

export type EventListState = typeof state;

export { observeEffect };
