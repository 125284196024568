import { entitiesPropsFactory } from '@ngneat/elf-entities';
import { createState, withProps } from '@ngneat/elf';
import {
  Events,
  EventsLineUp,
  EventIncidentType,
} from '@matchman/database/entities';

import { FetchStatus } from '../../../types';
import { defaultStatus } from '../../../utils';
import * as observeEffect from './observe';

const name = 'LineUpEvent';

export const { withLineUpEntities, lineUpEntitiesRef } =
  entitiesPropsFactory('lineUp');

export const { withEventIncidentTypeEntities, eventIncidentTypeEntitiesRef } =
  entitiesPropsFactory('eventIncidentType');

type LineUpStoreProps = {
  statusLineUp: FetchStatus;
  statusEvents: FetchStatus;
  statusEventIncidentType: FetchStatus;
  event: Events | null;
};

const { state, config } = createState(
  withLineUpEntities<EventsLineUp>(),
  withEventIncidentTypeEntities<EventIncidentType>(),
  withProps<LineUpStoreProps>({
    statusLineUp: defaultStatus(),
    statusEvents: defaultStatus(),
    statusEventIncidentType: defaultStatus(),
    event: null,
  })
);

export const options = { state, config, name };

export type LineUpState = typeof state;

export { observeEffect };
