import {
  Entity,
  PrimaryGeneratedColumn,
  Column,
  RelationId,
  ManyToOne,
  JoinColumn,
  UpdateDateColumn,
  OneToMany,
  ManyToMany,
  JoinTable,
} from 'typeorm';
import {
  StatusType,
  IStatusType,
  TournamentStages,
  ITournamentStages,
  Tournaments,
  ITournaments,
  Venues,
  IVenues,
  Teams,
  ITeams,
  EventsLineUp,
  IEventsLineUp,
  EventIncidents,
  IEventIncidents,
  Contest,
  IContest,
  IJoinUserContest,
  JoinUserContest,
  DemoTemplatesEvent,
  IDemoTemplatesEvent,
} from './';

export type IEvents = Events;

@Entity('events')
export class Events {
  @PrimaryGeneratedColumn()
  public id!: number;

  @Column({
    name: 'is_confirm_line_up',
  })
  isConfirmLineUp!: boolean;

  @Column({
    name: 'is_has_line_up',
  })
  isHasLineUp!: boolean;

  @ManyToOne(() => StatusType, (item) => item.id)
  @JoinColumn({
    name: 'status_id',
    referencedColumnName: 'id',
  })
  public status!: IStatusType;

  @RelationId((item: Events) => item.status)
  @Column({
    name: 'status_id',
  })
  public statusId!: number;

  @ManyToOne(() => Tournaments, (item) => item.id)
  @JoinColumn({
    name: 'tournamentFK',
    referencedColumnName: 'id',
  })
  public tournament!: ITournaments;

  @RelationId((item: Events) => item.tournament)
  @Column({
    name: 'tournamentFK',
  })
  tournamentFK!: number;

  @ManyToOne(() => TournamentStages, (item) => item.id)
  @JoinColumn({
    name: 'tournament_stageFK',
    referencedColumnName: 'id',
  })
  public tournamentStage!: ITournamentStages;

  @RelationId((item: Events) => item.tournamentStage)
  @Column({
    name: 'tournament_stageFK',
  })
  public tournamentStageFK!: number;

  @Column()
  public round!: string;

  @ManyToOne(() => Venues, (item) => item.name)
  @JoinColumn({
    name: 'venue_id',
    referencedColumnName: 'id',
  })
  public venue!: IVenues;

  @RelationId((item: Events) => item.venue)
  @Column({
    name: 'venue_id',
  })
  public venueId!: number;

  @ManyToOne(() => Teams, (item) => item.id)
  @JoinColumn({
    name: 'home_team_id',
    referencedColumnName: 'id',
  })
  public homeTeam!: ITeams;

  @RelationId((item: Events) => item.homeTeam)
  @Column({
    name: 'home_team_id',
  })
  public homeTeamId!: number;

  @ManyToOne(() => Teams, (item) => item.id)
  @JoinColumn({
    name: 'away_team_id',
    referencedColumnName: 'id',
  })
  public awayTeam!: ITeams;

  @RelationId((item: Events) => item.awayTeam)
  @Column({
    name: 'away_team_id',
  })
  awayTeamId!: number;

  @Column({
    name: 'home_team_score',
  })
  homeTeamScore!: number;

  @Column({
    name: 'away_team_score',
  })
  awayTeamScore!: number;

  @Column({
    name: 'start_date',
  })
  public startDate!: Date;

  @Column()
  public elapsed!: number;

  @Column({
    name: 'injury_time_elapsed',
  })
  public injuryTimeElapsed!: number;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;

  @OneToMany(() => EventsLineUp, (i) => i.events)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'event_id',
  })
  public lineUp!: IEventsLineUp[];

  @OneToMany(() => DemoTemplatesEvent, (i) => i.events)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'event_id',
  })
  public demoTemplatesEvents!: IDemoTemplatesEvent[];

  @OneToMany(() => EventIncidents, (item) => item.event)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'event_id',
  })
  public eventIncident!: IEventIncidents[];

  @OneToMany(() => Contest, (contest) => contest.event)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'event_id',
  })
  public contest!: IContest[];

  @OneToMany(() => JoinUserContest, (joinUserContest) => joinUserContest.event)
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'event_id',
  })
  public joinUserContest!: IJoinUserContest[];

  @ManyToMany(() => Events, (item) => item.mainEvent)
  public demoEvent!: IEvents[];

  @ManyToMany(() => Events, (item) => item.demoEvent)
  @JoinTable({
    name: 'event_has_demo',
    inverseJoinColumn: {
      referencedColumnName: 'id',
      name: 'event_id',
    },
    joinColumn: {
      referencedColumnName: 'id',
      name: 'demo_event_id',
    },
  })
  public mainEvent!: IEvents[];
}
