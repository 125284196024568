import {
  inject,
  InjectionToken,
  PLATFORM_ID,
  Provider,
  Type,
} from '@angular/core';
import { isPlatformServer } from '@angular/common';
import { getStore, StoreDef } from '@ngneat/elf';
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { Request } from 'express';

import { StoreService } from '../service/store.service';
import { ObserveFunc, OnStoreInit } from '../types';

export function isOnStoreInitDefined(cs: unknown): cs is OnStoreInit {
  return typeof (cs as OnStoreInit).onStoreInit === 'function';
}

export function provideStore<T = any>(
  storeClass: Type<StoreService<T>>,
  options: StoreDef<T>,
  observeFunc?: (() => ObserveFunc<T>)[]
): Provider[] {
  const stateToken = new InjectionToken<StoreDef<T>>(
    `state for: ${options.name}`
  );

  const observeFuncToken = new InjectionToken<[() => ObserveFunc<T>]>(
    'Observe func array'
  );

  return [
    {
      provide: observeFuncToken,
      useValue: observeFunc || [],
    },
    {
      provide: stateToken,
      useValue: options,
    },
    {
      provide: storeClass,
      useFactory: () => {
        const initState = inject(stateToken);
        const observeFuncArray = inject(observeFuncToken);
        const platformId = inject(PLATFORM_ID);
        if (isPlatformServer(platformId)) {
          const request = inject<Request>(REQUEST);
          // @ts-ignore
          options.name = [options.name, request['id']].join(':');
        }

        const store = getStore(options.name);
        if (store) {
          options.state = store.getValue();
          store.destroy();
        }

        const storeInst = new storeClass(initState);
        const observArray = observeFuncArray.map((r) => r.call(storeInst));
        if (observArray.length > 0) {
          storeInst.connect(...observArray);
        }
        if (isOnStoreInitDefined(storeInst)) {
          storeInst.onStoreInit();
        }
        return storeInst;
      },
    },
  ];
}
