import {
  PrimaryColumn,
  Entity,
  Column,
  RelationId,
  ManyToOne,
  UpdateDateColumn,
  JoinColumn,
} from 'typeorm';

import { Teams, ITeams, Players, IPlayers } from './';

export type ITeamsHasPlayers = TeamsHasPlayers;

@Entity('teams_has_players')
export class TeamsHasPlayers {
  @PrimaryColumn()
  public id!: number;

  @RelationId((item: TeamsHasPlayers) => item.team)
  @Column({
    name: 'team_id',
  })
  teamId!: number;

  @ManyToOne(() => Teams, (i) => i.teamsHasPlayers)
  @JoinColumn({
    name: 'team_id',
    referencedColumnName: 'id',
  })
  public team!: ITeams;

  @RelationId((item: TeamsHasPlayers) => item.onLoanToTeam)
  @Column({
    name: 'on_loan_to_team_id',
  })
  onLoanToTeamId!: number;

  @ManyToOne(() => Teams, (i) => i.teamsHasPlayers)
  @JoinColumn({
    name: 'on_loan_to_team_id',
    referencedColumnName: 'id',
  })
  public onLoanToTeam!: ITeams;

  @RelationId((item: TeamsHasPlayers) => item.onLoanFromTeam)
  @Column({
    name: 'on_loan_from_team_id',
  })
  onLoanFromTeamId!: number;

  @ManyToOne(() => Teams, (i) => i.teamsHasPlayers)
  @JoinColumn({
    name: 'on_loan_from_team_id',
    referencedColumnName: 'id',
  })
  public onLoanFromTeam!: ITeams;

  @RelationId((item: TeamsHasPlayers) => item.player)
  @Column({
    name: 'player_id',
  })
  public playerId!: number;

  @ManyToOne(() => Players, (i) => i.teamsHasPlayers)
  @JoinColumn({
    name: 'player_id',
    referencedColumnName: 'id',
  })
  public player!: IPlayers;

  @Column()
  public active!: boolean;

  @Column({
    name: 'shirt_number',
  })
  public shirtNumber!: number;

  @Column({
    name: 'date_from',
  })
  public dateFrom!: Date;

  @Column({
    name: 'date_to',
  })
  public dateTo!: Date;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;
}
