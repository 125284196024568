import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { filter, map, Observable, startWith } from 'rxjs';
import { CommonModule, NgIf } from '@angular/common';
import { NzMenuModule } from 'ng-zorro-antd/menu';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, NgIf, NzMenuModule, RouterLinkActive, RouterLink],
})
export class MenuComponent {
  router = inject(Router);

  currentUrl$: Observable<string> = this.router.events.pipe(
    filter((e) => e instanceof NavigationEnd),
    startWith(true),
    map(() => this.router.url)
  );
}
