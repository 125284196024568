import { inject } from '@angular/core';
import { ObserveFunc } from '@matchman/common-front';
import { EventIncidentType, EventIncidentTypePoint } from '@matchman/database';
import { getEntity, updateEntities } from '@ngneat/elf-entities';
import { JsonApiSdkService } from 'json-api-nestjs-sdk';

import { StateService } from '../../service/state.service';
import { IncidentListState, incidentEntitiesRef } from '../index';
import { saveIncidentPointEffect } from '../../effect';
import { EMPTY, filter, map, switchMap } from 'rxjs';

export function saveIncidentPoint(
  this: StateService
): ObserveFunc<IncidentListState> {
  const jsonApiUtilsService = inject(JsonApiSdkService);
  const saveIncidentPoint = inject(saveIncidentPointEffect);

  return saveIncidentPoint.asObservable().pipe(
    switchMap((r) => {
      r.incidentTypeId = parseInt(`${r.incidentTypeId}`, 10);
      const incidentType = this.query(
        getEntity(r.incidentTypeId, { ref: incidentEntitiesRef })
      );
      if (!incidentType) {
        return EMPTY;
      }
      const methode = incidentType.eventPoint ? 'patchOne' : 'postOne';
      return jsonApiUtilsService[methode](r);
    }),
    filter((r): r is EventIncidentTypePoint => !!r),
    map((r) => {
      const incidentType = this.query(
        getEntity(r.incidentTypeId, { ref: incidentEntitiesRef })
      );
      if (!incidentType) {
        return incidentType;
      }
      incidentType.eventPoint = { ...r };
      return incidentType;
    }),
    filter((r): r is EventIncidentType => !!r),
    map((r) =>
      this.reduceForUpdate(
        updateEntities(r.id, r, { ref: incidentEntitiesRef })
      )
    )
  );
}
