import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';

import {
  BillingAccountListComponent,
  SumData,
} from '../billing-account-list.component';

export const putMoneyEffect = new InjectionToken<Subject<SumData>>(
  'Put Money Effect',
  {
    providedIn: BillingAccountListComponent,
    factory: () => new Subject<SumData>(),
  }
);
