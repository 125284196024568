import { inject } from '@angular/core';
import { ObserveFunc } from '@matchman/common-front';
import { DemoTemplatesEvent, Events } from '@matchman/database/entities';
import { setEntities } from '@ngneat/elf-entities';
import { map, switchMap } from 'rxjs/operators';

import { loadDemoEventEffect } from '../../effect';
import { StateService } from '../../service/state.service';
import { DemoEventState, demoEventEntitiesRef, eventsEntitiesRef } from '../';
import { JsonApiSdkService } from 'json-api-nestjs-sdk';
import { catchError, of, startWith } from 'rxjs';
import { setProp } from '@ngneat/elf';
import { endWithAsyncPipe } from '@matchman/frontend-utils';

export function loadDemoEvent(this: StateService): ObserveFunc<DemoEventState> {
  const loadDemoEvent = inject(loadDemoEventEffect);
  const jsonApiUtilsService = inject(JsonApiSdkService);

  const loadDemoEvent$ = jsonApiUtilsService.getAll(DemoTemplatesEvent, {
    page: {
      size: 100,
      number: 1,
    },
  });

  const loadEvent$ = (eventIds: string[]) =>
    jsonApiUtilsService.getAll(Events, {
      filter: {
        target: {
          id: {
            in: eventIds,
          },
        },
      },
      page: {
        size: 100,
        number: 1,
      },
      include: ['awayTeam', 'homeTeam'],
    });

  return loadDemoEvent.asObservable().pipe(
    switchMap(() =>
      loadDemoEvent$.pipe(
        switchMap((demoEvent) => {
          const eventIds = demoEvent.map((i) => `${i.eventId}`);
          return loadEvent$(eventIds).pipe(
            map((eventList) => ({ eventList, demoEvent }))
          );
        }),
        map(({ demoEvent, eventList }) =>
          this.reduceForUpdate(
            setEntities(demoEvent, { ref: demoEventEntitiesRef }),
            setEntities(eventList, { ref: eventsEntitiesRef })
          )
        ),
        startWith(
          this.reduceForUpdate(
            setProp('statusEventDemo', (state) => ({
              ...state,
              ...{
                isLoading: true,
                status: 'idle' as typeof state['status'],
              },
            }))
          )
        ),
        endWithAsyncPipe(
          this.reduceForUpdate(
            setProp('statusEventDemo', (state) => ({
              ...state,
              ...{
                isLoading: false,
                isSuccess: true,
                status: 'done' as typeof state['status'],
              },
            }))
          )
        ),
        catchError((err) => {
          return of(
            this.reduceForUpdate(
              setProp('statusEventDemo', (state) => ({
                ...state,
                ...{
                  isLoading: false,
                  isSuccess: false,
                  isError: true,
                  status: 'done' as typeof state['status'],
                  error: err,
                },
              }))
            )
          );
        })
      )
    )
  );
}
