import { entitiesPropsFactory } from '@ngneat/elf-entities';
import { createState, withProps } from '@ngneat/elf';
import { DemoTemplatesEvent, Events } from '@matchman/database/entities';

import { FetchStatus } from '../../../types';
import { defaultStatus } from '../../../utils';

import * as observeEffect from './observe';

const name = 'DemoEventConfigState';

export const { withDemoEventEntities, demoEventEntitiesRef } =
  entitiesPropsFactory('demoEvent');

export const { withEventsEntities, eventsEntitiesRef } =
  entitiesPropsFactory('events');

type DemoEventStoreProps = {
  statusEventDemo: FetchStatus;
};

const { state, config } = createState(
  withDemoEventEntities<DemoTemplatesEvent>(),
  withEventsEntities<Events>(),
  withProps<DemoEventStoreProps>({
    statusEventDemo: defaultStatus(),
  })
);

export const options = { state, config, name };

export type DemoEventState = typeof state;

export { observeEffect };
