import { importProvidersFrom } from '@angular/core';
import {
  Contest,
  EventIncidents,
  EventIncidentType,
  Events,
  EventsLineUp,
  JoinUserContest,
  Players,
  PlayersEmaHistory,
  PlayerUserContest,
  StatusType,
  Teams,
  Tournaments,
  TournamentStages,
  TournamentTemplates,
  Users,
  PositionsType,
  IncidentHasPoint,
  EventIncidentTypeText,
  EventIncidentTypePoint,
  DemoTemplatesEvent,
  BillingAccount,
  BillingTransaction,
} from '@matchman/database/entities';
import { JsonApiNestjsSdkModule } from 'json-api-nestjs-sdk';

import { ConfigApplication } from '../types';

export default (config: ConfigApplication) =>
  importProvidersFrom(
    JsonApiNestjsSdkModule.forRoot(
      {
        apiPrefix: '/api/v1',
        apiHost: config.apiUrl,
      },
      {
        Events,
        StatusType,
        TournamentStages,
        Teams,
        Tournaments,
        TournamentTemplates,
        EventsLineUp,
        Players,
        PlayersEmaHistory,
        EventIncidents,
        EventIncidentType,
        Contest,
        Users,
        JoinUserContest,
        PlayerUserContest,
        PositionsType,
        IncidentHasPoint,
        EventIncidentTypeText,
        EventIncidentTypePoint,
        DemoTemplatesEvent,
        BillingAccount,
        BillingTransaction,
      }
    )
  );
