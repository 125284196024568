import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';

import { LineUpForEventComponent } from '../line-up-for-event.component';

export const loadEventIncidentTypeEffect = new InjectionToken<
  Subject<undefined>
>('LoadEventIncidentTypeEffect', {
  providedIn: LineUpForEventComponent,
  factory: () => new Subject(),
});
