import {
  Column,
  Entity,
  JoinColumn,
  JoinTable,
  ManyToMany,
  ManyToOne,
  OneToMany,
  PrimaryColumn,
  RelationId,
  UpdateDateColumn,
} from 'typeorm';

import {
  TournamentTemplates,
  ITournamentTemplate,
  Teams,
  ITeams,
  TournamentStages,
  ITournamentStages,
} from './';

export type ITournaments = Tournaments;

@Entity('tournaments')
export class Tournaments {
  @PrimaryColumn()
  public id!: number;

  @ManyToOne(() => TournamentTemplates, (item) => item.id)
  @JoinColumn({
    name: 'tournament_templateFK',
  })
  public tournamentTemplate!: ITournamentTemplate;

  @RelationId((item: Tournaments) => item.tournamentTemplate)
  @Column({
    name: 'tournament_templateFK',
  })
  tournamentTemplateFK!: number;

  @Column()
  public name!: string;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;

  @OneToMany(() => TournamentStages, (item) => item.tournaments)
  public tournamentStages!: ITournamentStages[];
}
