<nz-breadcrumb>
  <nz-breadcrumb-item>
    <a [routerLink]="['./']">
      <span nz-icon nzType="home"></span>
    </a>
  </nz-breadcrumb-item>
  <nz-breadcrumb-item *ngFor="let breadcrumb of breadcrumbs | async">
    <a [routerLink]="breadcrumb.url">{{ breadcrumb.label }}</a>
  </nz-breadcrumb-item>
</nz-breadcrumb>
