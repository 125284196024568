import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { RxLet } from '@rx-angular/template/let';
import { provideStore } from '@matchman/common-front';
import { Events } from '@matchman/database/entities';
import { NzTableModule, NzTableQueryParams } from 'ng-zorro-antd/table';
import { Observable, shareReplay } from 'rxjs';
import { format } from 'date-fns';

import { EventSearchFormComponent } from '../event-search-form/event-search-form.component';
import {
  SearchFormValue,
  SearchValue,
} from '../event-search-form/service/event-search-form.service';
import { getEventFilter } from '../../utils';
import { EventListState, observeEffect, options } from './state';
import { StateService } from './service/state.service';
import { dateForm } from '../../constant/inex';
import { ResizeTableDirective } from '../../directives/resize-table.directive';

function isDate(data: number | Date): data is Date {
  return !!data;
}

@Component({
  selector: 'app-events-list',
  standalone: true,
  imports: [
    CommonModule,
    EventSearchFormComponent,
    NzTableModule,
    RouterLink,
    RxLet,
    ResizeTableDirective,
  ],
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ...provideStore<EventListState>(
      StateService,
      options,
      Object.values(observeEffect)
    ),
  ],
})
export class EventsListComponent implements OnDestroy {
  protected router = inject(Router);
  protected activatedRoute = inject(ActivatedRoute);
  protected stateService = inject(StateService);

  searchFilter$: Observable<SearchFormValue> =
    this.activatedRoute.queryParams.pipe(
      getEventFilter(),
      shareReplay({ refCount: true })
    );

  vm$ = this.stateService.vm$;

  setParamToRouter($event: SearchValue) {
    const queryFilter = Object.entries($event)
      .map(([key, value]) => {
        if (key === 'startDate' && isDate(value)) {
          return [key, format(value, dateForm)];
        }
        if (key === 'endDate' && isDate(value)) {
          return [key, format(value, dateForm)];
        }
        return [key, value];
      })
      .reduce<Record<string, unknown>>((acum, [key, value]) => {
        acum[`filter[${key}]`] = value;
        return acum;
      }, {});
    if (Object.keys(queryFilter).length > 0) {
      queryFilter['page'] = 1;
    }

    this.router.navigate([], {
      queryParams: queryFilter,
    });
  }

  trackBy(index: number, item: Events): number {
    return item.id;
  }

  onQueryParamsChange(params: NzTableQueryParams) {
    const { page } = this.activatedRoute.snapshot.queryParams;
    if (!page && params.pageIndex === 1) {
      return;
    }
    this.router.navigate([], {
      queryParams: { page: params.pageIndex },
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy() {
    this.stateService.destroy();
  }
}
