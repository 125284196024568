import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';

import { IncidentListComponent } from '../incident-list.component';

export const loadEventIncidentEffect = new InjectionToken<Subject<undefined>>(
  'loadEventIncidentEffect',
  {
    providedIn: IncidentListComponent,
    factory: () => new Subject(),
  }
);
