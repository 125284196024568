import {
  Column,
  Entity,
  JoinColumn,
  ManyToOne,
  OneToOne,
  OneToMany,
  PrimaryGeneratedColumn,
  RelationId,
} from 'typeorm';
import {
  Events,
  IEvents,
  IUsers,
  Users,
  Contest,
  IContest,
  PlayerUserContest,
  IPlayerUserContest,
  IBillingHold,
  BillingHold,
} from './';

export type IJoinUserContest = JoinUserContest;

@Entity('join_user_contest')
export class JoinUserContest {
  @PrimaryGeneratedColumn()
  id!: number;

  @RelationId((item: JoinUserContest) => item.event)
  @Column({
    name: 'event_id',
  })
  eventId!: number;

  @ManyToOne(() => Events, (item) => item.id)
  @JoinColumn({
    name: 'event_id',
    referencedColumnName: 'id',
  })
  public event!: IEvents;

  @RelationId((item: JoinUserContest) => item.user)
  @Column({
    name: 'user_id',
  })
  public userId!: number;

  @ManyToOne(() => Users, (item) => item.id)
  @JoinColumn({
    name: 'user_id',
    referencedColumnName: 'id',
  })
  public user!: IUsers;

  @RelationId((item: JoinUserContest) => item.contest)
  @Column({
    name: 'contest_id',
  })
  public contestId!: number;

  @ManyToOne(() => Contest, (item) => item.id)
  @JoinColumn({
    name: 'contest_id',
    referencedColumnName: 'id',
  })
  public contest!: IContest;

  @RelationId((item: JoinUserContest) => item.hold)
  @Column({
    name: 'hold_id',
  })
  public holdId!: number | null;

  @OneToOne(() => BillingHold, (item) => item.id)
  @JoinColumn({
    name: 'hold_id',
    referencedColumnName: 'id',
  })
  public hold!: IBillingHold | null;

  @Column()
  public place!: number;

  @Column({
    type: 'int',
    array: true,
  })
  public schema!: number[];

  @Column({
    name: 'date_join',
  })
  dateJoin!: Date;

  @Column()
  public point!: number;

  @OneToMany(
    () => PlayerUserContest,
    (playerUserContest) => playerUserContest.joinUserContest
  )
  @JoinColumn({
    name: 'id',
    referencedColumnName: 'join_user_contest_id',
  })
  public playerUserContest!: IPlayerUserContest[];
}
