export enum GenderEnum {
  Mixed = 'mixed',
  Male = 'male',
  Female = 'female',
}

export type PointByPosition = {
  [key in Exclude<
    PositionEnum,
    | PositionEnum.Unknown
    | PositionEnum.SubstitutePlayer
    | PositionEnum.Coach
    | PositionEnum.Injured
  >]: number;
};

export enum PositionEnum {
  Goalkeeper = 'Goalkeeper',
  Defence = 'Defence',
  Midfield = 'Midfield',
  Forward = 'Forward',
  Unknown = 'Unknown',
  SubstitutePlayer = 'Substitute player',
  Coach = 'Coach',
  Injured = 'Injured',
}

export enum EventStatusEnum {
  Notstarted = 'notstarted',
  Inprogress = 'inprogress',
  Finished = 'finished',
  Interrupted = 'interrupted',
  Unknown = 'unknown',
  Cancelled = 'cancelled',
  Deleted = 'deleted',
}

export enum ResultStatusEnum {
  Ordinarytime = 'ordinarytime',
  Extratime = 'extratime',
  Penaltyshootout = 'penaltyshootout',
  Finalresult = 'finalresult',
  Halftime = 'halftime',
  Runningscore = 'runningscore',
  StrokesR1 = 'strokes_r1',
  StrokesR2 = 'strokes_r2',
  StrokesR3 = 'strokes_r3',
  StrokesR4 = 'strokes_r4',
  StrokesR5 = 'strokes_r5',
  Par = 'par',
  Position = 'position',
  MadeCut = 'made_cut',
  Mpscore = 'mpscore',
  Weight = 'weight',
  Period1 = 'period1',
  Period2 = 'period2',
  Period3 = 'period3',
  Quarter1 = 'quarter1',
  Quarter2 = 'quarter2',
  Quarter3 = 'quarter3',
  Quarter4 = 'quarter4',
  Set1 = 'set1',
  Set2 = 'set2',
  Set3 = 'set3',
  Set4 = 'set4',
  Set5 = 'set5',
  Setswon = 'setswon',
  Tiebreak1 = 'tiebreak1',
  Tiebreak2 = 'tiebreak2',
  Tiebreak3 = 'tiebreak3',
  Tiebreak4 = 'tiebreak4',
  Tiebreak5 = 'tiebreak5',
  Gamescore = 'gamescore',
  Set6 = 'set6',
  Set7 = 'set7',
  Rank = 'rank',
  Duration = 'duration',
  Points = 'points',
  Distance = 'distance',
  Comment = 'comment',
  Laps = 'laps',
  LapsBehind = 'laps_behind',
  Pitstops = 'pitstops',
  Inning1 = 'inning1',
  Inning2 = 'inning2',
  Inning3 = 'inning3',
  Inning4 = 'inning4',
  Inning5 = 'inning5',
  Inning6 = 'inning6',
  Inning7 = 'inning7',
  Inning8 = 'inning8',
  Inning9 = 'inning9',
  ExtraInning = 'extra_inning',
  Hits = 'hits',
  Errors = 'errors',
  Misses = 'misses',
  Horseracingodds = 'horseracingodds',
  Startnumber = 'startnumber',
  Medal = 'medal',
  MissedShots = 'missed_shots',
  AdditionalShots = 'additional_shots',
  Tries = 'tries',
  '4s' = '4s',
  '6s' = '6s',
  Overs = 'overs',
  Extra = 'extra',
  Wickets = 'wickets',
  Secondpoints = 'secondpoints',
  Secondovers = 'secondovers',
  Secondextra = 'secondextra',
  Secondwickets = 'secondwickets',
  Speed = 'speed',
  JumpOffPenalties = 'jump_off_penalties',
  JumpOffTime = 'jump_off_time',
  Netpoints = 'netpoints',
  Drawnumber = 'drawnumber',
  OfficialRating = 'official_rating',
  Form = 'form',
  Age = 'age',
  FastestLapPoint = 'fastest_lap_point',
  Handicap = 'handicap',
  PlaceWin = 'place_win',
  Allowance = 'allowance',
  StrokesPointsR1 = 'strokes_points_r1',
  StrokesPointsR2 = 'strokes_points_r2',
  StrokesPointsR3 = 'strokes_points_r3',
  StrokesPointsR4 = 'strokes_points_r4',
  StrokesPointsR5 = 'strokes_points_r5',
  Wins = 'wins',
  Rides = 'rides',
  BestTime = 'best_time',
  Classificationpoints = 'classificationpoints',
  Tops = 'tops',
  Zones = 'zones',
  TopAttempts = 'top_attempts',
  ZoneAttempts = 'zone_attempts',
  Interval = 'interval',
  PrizeMoney = 'prize_money',
  Half1 = 'half1',
  Half2 = 'half2',
  Ipons = 'ipons',
  WazaAri = 'waza_ari',
  Penalties = 'penalties',
}

export enum PositionTypeEnum {
  Goalkeeper = 'Goalkeeper',
  Defence = 'Defence',
  Midfield = 'Midfield',
  Forward = 'Forward',
  SubstitutePlayer = 'Substitute player',
  Unknown = 'Unknown',
  Injured = 'Injured',
  Suspended = 'Suspended',
  Unavailable = 'Unavailable',
  Coach = 'Coach',
  Pitcher = 'Pitcher',
  Batter = 'Batter ',
  Doubtful = 'Doubtful',
  Starter = 'Starter',
  SecondCoach = 'Second Coach',
  NationalTeamDuty = 'National team duty',
  DidNotPlay = 'Did not play',
  Top = 'Top',
  Jungle = 'Jungle',
  Mid = 'Mid',
  Adc = 'Adc',
  Support = 'Support',
  Illness = 'Illness',
  PersonalMatter = 'Personal matter',
  LackOfFitness = 'Lack of fitness',
  CoachDecision = 'Coach decision',
  SubstitutePitcher = 'Substitute pitcher',
}

export enum EventTextType {
  Player1 = 'player1',
  Team = 'team',
  Player2 = 'player2',
  Goalkeeper = 'goalkeeper',
  InjuryTime = 'injury_time',
  Spectators = 'spectators',
  Venue = 'venue',
  Homepos = 'homepos',
  Awaypos = 'awaypos',
  Comment = 'comment',
  Player1Value = 'player1_value',
  Player2Value = 'player2_value',
  StatusTime = 'statustime',
  Team1 = 'team1',
  Team2 = 'team2',
  Coach1 = 'coach1',
  Coach2 = 'coach2',
  GoalType = 'goal_type',
  EventIncidentFK = 'event_incidentFK',
}

export enum SubstitutionEnum {
  In = 'in',
  Out = 'out',
}

export enum ContestType {
  DUEL = 'duel',
  KING_OF_THE_HILL = 'king_of_the_hill',
  SCRAMBLE = 'scramble',
  FREE = 'free',
  PERSONAL = 'personal',
}

export enum Currency {
  USD = 'USD',
}

export enum AmountType {
  System = 'System',
  User = 'User',
  Payment = 'Payment',
  Mock = 'Mock',
}
