import {
  ChangeDetectionStrategy,
  Component,
  inject,
  OnInit,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { en_US, NzI18nService } from 'ng-zorro-antd/i18n';

@Component({
  selector: 'app-bootstrap',
  standalone: true,
  imports: [CommonModule, RouterOutlet],
  templateUrl: './bootstrap.component.html',
  styleUrls: ['./bootstrap.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BootstrapComponent implements OnInit {
  private i18n: NzI18nService = inject(NzI18nService);

  ngOnInit(): void {
    this.i18n.setLocale(en_US);
  }
}
