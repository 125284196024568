import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'showDayOfWeek',
  standalone: true,
})
export class ShowDayOfWeekPipe implements PipeTransform {
  protected days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  transform(value: number[]): string {
    return value.map((i) => this.days[i]).join(', ');
  }
}
