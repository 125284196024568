import { inject } from '@angular/core';
import { setProp } from '@ngneat/elf';
import { setEntities } from '@ngneat/elf-entities';
import {
  endWithAsyncPipe,
  getNumberFromParamsPipe,
} from '@matchman/frontend-utils';
import { ObserveFunc } from '@matchman/common-front';
import { BillingAccount } from '@matchman/database/entities';
import { map, switchMap } from 'rxjs/operators';
import { JsonApiSdkService } from 'json-api-nestjs-sdk';
import { catchError, of, startWith } from 'rxjs';

import { StateService } from '../../service/state.service';
import { BillingAccountListState, accountListEntitiesRef } from '../';
import { ActivatedRoute } from '@angular/router';

export function loadBillingAccount(
  this: StateService
): ObserveFunc<BillingAccountListState> {
  const jsonApiUtilsService = inject(JsonApiSdkService);
  const activatedRoute = inject(ActivatedRoute);

  const loadAccount$ = (pageNumber: number, pageSize: number) =>
    jsonApiUtilsService
      .getAll(BillingAccount, {
        include: ['user'],
        page: {
          number: pageNumber,
          size: pageSize,
        },
      })
      .pipe(
        map((r) => {
          const { pageNumber, pageSize, totalItems } = r;
          const list = r.map((i) => {
            i.id = parseInt(`${i.id}`, 10);
            return i;
          });
          return { list, pageNumber, pageSize, totalItems };
        }),
        map(({ list, pageNumber, pageSize, totalItems }) =>
          this.reduceForUpdate(
            setEntities(list, { ref: accountListEntitiesRef }),
            setProp('pageInfo', {
              page: pageNumber,
              pageSize,
              countAll: totalItems,
            })
          )
        ),
        startWith(
          this.reduceForUpdate(
            setProp('accountListStatus', (state) => ({
              ...state,
              ...{
                isLoading: true,
                status: 'idle' as typeof state['status'],
              },
            }))
          )
        ),
        endWithAsyncPipe(
          this.reduceForUpdate(
            setProp('accountListStatus', (state) => ({
              ...state,
              ...{
                isLoading: false,
                isSuccess: true,
                status: 'done' as typeof state['status'],
              },
            }))
          )
        ),
        catchError((err) => {
          return of(
            this.reduceForUpdate(
              setProp('accountListStatus', (state) => ({
                ...state,
                ...{
                  isLoading: false,
                  isSuccess: false,
                  isError: true,
                  status: 'done' as typeof state['status'],
                  error: err,
                },
              }))
            )
          );
        })
      );

  return activatedRoute.queryParamMap.pipe(
    getNumberFromParamsPipe('page', '1'),
    map((page) => {
      const { pageSize } = this.query((state) => state.pageInfo);
      return {
        pageNumber: page,
        pageSize,
      };
    }),
    switchMap(({ pageNumber, pageSize }) => loadAccount$(pageNumber, pageSize))
  );
}
