import {
  Column,
  Entity,
  OneToMany,
  PrimaryColumn,
  UpdateDateColumn,
} from 'typeorm';
import {
  ITournamentStages,
  TournamentStages,
  Venues,
  IVenues,
  Teams,
  ITeams,
} from './';

export type ICountries = Countries;

@Entity('countries')
export class Countries {
  @PrimaryColumn()
  public id!: number;

  @Column()
  public name!: string;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;

  @OneToMany(() => TournamentStages, (item) => item.country)
  public tournamentTemplate!: ITournamentStages[];

  @OneToMany(() => Venues, (item) => item.country)
  public venues!: IVenues[];

  @OneToMany(() => Teams, (item) => item.country)
  public teams!: ITeams[];
}
