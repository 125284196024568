import { InjectionToken } from '@angular/core';
import { Subject } from 'rxjs';

import { EventSearchFormComponent } from '../event-search-form.component';

export const loadTournamentEffect = new InjectionToken<Subject<undefined>>(
  'LoadTournamentEffect',
  {
    providedIn: EventSearchFormComponent,
    factory: () => new Subject(),
  }
);
