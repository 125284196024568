import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  signal,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { BillingAccount } from '@matchman/database/entities';
import { AmountType } from '@matchman/database/types';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzInputModule } from 'ng-zorro-antd/input';
import { Form } from '../../types';
import { FormControl, FormGroup, ReactiveFormsModule } from '@angular/forms';

export type BillingAccountData = {
  [K in keyof Pick<BillingAccount, 'amountType' | 'details' | 'meta' | 'id'>]:
    | BillingAccount[K]
    | null;
};

type BillingAccountForm = Form<BillingAccountData>;

@Component({
  selector: 'app-account-form',
  standalone: true,
  imports: [
    CommonModule,
    NzModalModule,
    NzFormModule,
    NzSelectModule,
    NzInputModule,
    ReactiveFormsModule,
  ],
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.less'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountFormComponent {
  form = new FormGroup<BillingAccountForm>({
    details: new FormControl(null),
    amountType: new FormControl(null),
    meta: new FormControl(JSON.stringify({})),
    id: new FormControl(null),
  });
  amountType = Object.values(AmountType);

  get formData() {
    const formData = this.form.getRawValue();
    formData.meta = JSON.parse(`${formData.meta}`);
    return formData;
  }

  @Input({ required: true }) set account(account: BillingAccount) {
    const { details, amountType, meta, id } = account;
    this.form.patchValue({
      details,
      amountType,
      meta: JSON.stringify(meta || {}),
      id,
    });
  }
}
