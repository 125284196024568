import { entitiesPropsFactory } from '@ngneat/elf-entities';
import { createState, withProps } from '@ngneat/elf';
import { IncidentHasPoint } from '@matchman/database/entities';

import * as observeEffect from './observe';
import { FetchStatus } from '../../../types';
import { defaultStatus } from '../../../utils';

const name = 'IncidentPlayer';

export const { withIncidentEntities, incidentEntitiesRef } =
  entitiesPropsFactory('incident');

type IncidentPlayerStoreProps = {
  statusIncident: FetchStatus;
};

const { state, config } = createState(
  withIncidentEntities<IncidentHasPoint>(),
  withProps<IncidentPlayerStoreProps>({
    statusIncident: defaultStatus(),
  })
);

export const options = { state, config, name };

export type IncidentPlayerState = typeof state;

export { observeEffect };
