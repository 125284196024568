import { entitiesPropsFactory } from '@ngneat/elf-entities';
import { createState, withProps } from '@ngneat/elf';
import {
  Teams,
  Tournaments,
  TournamentTemplates,
} from '@matchman/database/entities';
import { FetchStatus } from '../../../types';
import { defaultStatus } from '../../../utils';
import * as observeEffect from './observe';

const name = 'SearchFormState';

export const { withTeamsEntities, teamsEntitiesRef } =
  entitiesPropsFactory('teams');

export const { withTournamentsEntities, tournamentsEntitiesRef } =
  entitiesPropsFactory('tournaments');

export const {
  withTournamentTemplatesEntities,
  tournamentTemplatesEntitiesRef,
} = entitiesPropsFactory('tournamentTemplates');

type FormSearchStoreProps = {
  statusTeam: FetchStatus;
  statusTournamentTemplates: FetchStatus;
  statusTournament: FetchStatus;
};

const { state, config } = createState(
  withTeamsEntities<Teams>(),
  withTournamentTemplatesEntities<TournamentTemplates>(),
  withTournamentsEntities<Tournaments>(),
  withProps<FormSearchStoreProps>({
    statusTeam: defaultStatus(),
    statusTournament: defaultStatus(),
    statusTournamentTemplates: defaultStatus(),
  })
);

export const options = { state, config, name };

export type SearchFormState = typeof state;

export { observeEffect };
