<form [formGroup]="eventSearchForm" nz-form *rxLet="vm$; let vm">
  <div nz-row [nzGutter]="24">
    <div nz-col [nzSpan]="4">
      <nz-form-item>
        <nz-form-label nzFor="tournamentTemplates"
        >Main Tournament</nz-form-label
        >
        <nz-form-control>
          <nz-select
            id="tournamentTemplates"
            [formControl]="eventSearchForm.controls.tournamentTemplates"
            [nzLoading]="vm.tournamentTemplatesProps.isLoading"
            nzPlaceHolder="Main Tournament"
            nzAllowClear="true"
          >
            <nz-option
              *ngFor="let item of vm.tournamentTemplatesItem; trackBy: trackBy"
              [nzLabel]="item.name"
              [nzValue]="item.id"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="4">
      <nz-form-item>
        <nz-form-label nzFor="tournament">Tournament</nz-form-label>
        <nz-form-control>
          <nz-select
            id="tournament"
            [formControl]="eventSearchForm.controls.tournament"
            [nzLoading]="vm.tournamentProps.isLoading"
            [nzPlaceHolder]="
              eventSearchForm.controls.tournamentTemplates.value
                ? 'Select Tournament'
                : 'Select Main Tournament'
            "
            nzAllowClear="true"
          >
            <nz-option
              *ngFor="let item of vm.tournamentItems; trackBy: trackBy"
              [nzLabel]="item.name"
              [nzValue]="item.id"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="4">
      <nz-form-item>
        <nz-form-label nzFor="home-team"> Home Team </nz-form-label>
        <nz-form-control>
          <nz-select
            id="home-team"
            [formControl]="eventSearchForm.controls.homeTeam"
            [nzLoading]="vm.teamsProp.isLoading"
            [nzPlaceHolder]="
              eventSearchForm.controls.tournament.value
                ? 'Select Home Team'
                : 'Select Tournament'
            "
            nzAllowClear="true"
          >
            <nz-option
              *ngFor="let item of vm.teamsItems; trackBy: trackBy"
              [nzLabel]="item.name"
              [nzValue]="item.id"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="4">
      <nz-form-item>
        <nz-form-label nzFor="away-team"> Away Team </nz-form-label>
        <nz-form-control>
          <nz-select
            id="away-team"
            [formControl]="eventSearchForm.controls.awayTeam"
            [nzLoading]="vm.teamsProp.isLoading"
            [nzPlaceHolder]="
              eventSearchForm.controls.tournament.value
                ? 'Select Away Team'
                : 'Select Tournament'
            "
            nzAllowClear="true"
          >
            <nz-option
              *ngFor="let item of vm.teamsItems; trackBy: trackBy"
              [nzLabel]="item.name"
              [nzValue]="item.id"
            ></nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="5">
      <nz-form-item>
        <nz-form-label nzFor="away-team">Event Date</nz-form-label>
        <nz-form-control>
          <nz-range-picker nzMode="date" [formControl]="eventSearchForm.controls.eventDate"></nz-range-picker>
        </nz-form-control>
      </nz-form-item>
    </div>
    <div nz-col [nzSpan]="3">
      <nz-form-item>
        <nz-form-label nzFor="away-team">Show Demo</nz-form-label>
        <nz-form-control>
          <label nz-checkbox [formControl]="eventSearchForm.controls.showDemo"></label>
        </nz-form-control>
      </nz-form-item>
    </div>
  </div>
</form>
