import {
  Column,
  JoinColumn,
  ManyToOne,
  RelationId,
  Entity,
  PrimaryGeneratedColumn,
} from 'typeorm';
import { Events, IEvents } from './';

export type IDemoTemplatesEvent = DemoTemplatesEvent;

@Entity('demo_templates_event')
export class DemoTemplatesEvent {
  @PrimaryGeneratedColumn()
  public id!: number;

  @ManyToOne(() => Events, (item) => item.demoTemplatesEvents)
  @JoinColumn({
    name: 'event_id',
    referencedColumnName: 'id',
  })
  public events!: IEvents;

  @RelationId((item: DemoTemplatesEvent) => item.events)
  @Column({
    name: 'event_id',
  })
  eventId!: number;

  @Column({
    name: 'day_of_week',
    type: 'int',
    array: true,
  })
  dayOfWeek!: number[];

  @Column({
    name: 'time_of_day',
    type: 'time',
    array: true,
  })
  timeOfDay!: string[];
}
