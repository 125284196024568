import {
  Column,
  Entity,
  JoinColumn,
  OneToMany,
  OneToOne,
  PrimaryColumn,
  UpdateDateColumn,
} from 'typeorm';

import {
  EventIncidentTypeText,
  IEventIncidentTypeText,
  EventIncidents,
  IEventIncidents,
  EventIncidentTypePoint,
  IEventIncidentTypePoint,
} from './';

import { IsString } from 'class-validator';

export type IEventIncidentType = EventIncidentType;

@Entity('event_incident_type')
export class EventIncidentType {
  @PrimaryColumn()
  public id!: number;

  @IsString()
  @Column()
  public describe!: string;

  @Column()
  public player1!: boolean | null;

  @Column()
  public player2!: boolean | null;

  @Column()
  public team!: boolean | null;

  @Column()
  public comment!: string | null;

  @Column()
  public subtype1!: string | null;

  @Column()
  public subtype2!: string | null;

  @Column()
  public name!: string;

  @Column()
  public type!: string;

  @Column({
    name: 'comment_type',
  })
  public commentType!: string | null;

  @Column({
    name: 'player2_type',
  })
  public player2Type!: string | null;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;

  @OneToMany(() => EventIncidentTypeText, (item) => item.eventIncidentType)
  public eventIncidentTypeText!: IEventIncidentTypeText[];

  @OneToMany(() => EventIncidents, (item) => item.eventIncidentType)
  public eventIncident!: IEventIncidents[];

  @OneToOne(() => EventIncidentTypePoint, (item) => item.incidentType)
  public eventPoint!: IEventIncidentTypePoint;
}
