import { Route } from '@angular/router';
import { isAuth } from '@matchman/frontend-utils';

export const appRoutes: Route[] = [
  {
    path: '',
    canMatch: [isAuth],
    data: {
      breadcrumb: 'Main',
    },
    loadComponent: () =>
      import('../components/main/main.component').then((r) => r.MainComponent),
    children: [
      {
        path: 'events',
        data: {
          breadcrumb: 'Events List',
        },
        loadChildren: () =>
          import('./events.routes').then((r) => r.eventsRoutes),
      },
      {
        path: 'config',
        data: {
          breadcrumb: 'Configuration',
        },
        loadChildren: () => import('./config').then((r) => r.configRoutes),
      },
      {
        path: 'billing',
        data: {
          breadcrumb: 'Billing',
        },
        loadChildren: () => import('./billing').then((r) => r.billingRoutes),
      },
    ],
  },
];
