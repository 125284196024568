import { inject } from '@angular/core';
import { ObserveFunc } from '@matchman/common-front';
import { EventIncidentType } from '@matchman/database/entities';
import { endWithAsyncPipe } from '@matchman/frontend-utils';
import { setProp } from '@ngneat/elf';
import { upsertEntities } from '@ngneat/elf-entities';
import { expand, map, switchMap } from 'rxjs/operators';
import { JsonApiSdkService } from 'json-api-nestjs-sdk';
import { QueryField } from 'json-api-nestjs';

import { StateService } from '../../service/state.service';
import { loadEventIncidentEffect } from '../../effect';
import { IncidentListState, incidentEntitiesRef } from '../';
import {
  catchError,
  EMPTY,
  filter,
  merge,
  of,
  startWith,
  Subject,
  tap,
} from 'rxjs';

export function loadIncident(
  this: StateService
): ObserveFunc<IncidentListState> {
  const loadEventIncident = inject(loadEventIncidentEffect);
  const jsonApiUtilsService = inject(JsonApiSdkService);

  const subjectStream = new Subject<EventIncidentType[]>();

  const loadEventIncident$ = jsonApiUtilsService
    .getAll(EventIncidentType, {
      [QueryField.page]: {
        number: 1,
        size: 300,
      },
      [QueryField.include]: ['eventPoint', 'eventIncidentTypeText'],
    })
    .pipe(
      expand((r) => {
        if (r.pageNumber * r.pageSize >= r.totalItems) {
          subjectStream.complete();
          return EMPTY;
        }
        subjectStream.next(r);
        return jsonApiUtilsService
          .getAll(EventIncidentType, {
            [QueryField.page]: {
              number: r.pageNumber + 1,
              size: r.pageSize,
            },
            [QueryField.include]: ['eventPoint', 'eventIncidentTypeText'],
          })
          .pipe(tap((r) => subjectStream.next(r)));
      }),
      map(() => undefined),
      filter((r) => false)
    );

  const subjectStream$ = subjectStream.asObservable().pipe(
    map((r) =>
      this.reduceForUpdate(
        upsertEntities(r, { ref: incidentEntitiesRef }),
        setProp('statusIncidents', (state) => ({
          ...state,
          ...{
            isLoading: false,
            isSuccess: true,
            status: 'done' as typeof state['status'],
          },
        }))
      )
    ),
    startWith(
      this.reduceForUpdate(
        setProp('statusIncidents', (state) => ({
          ...state,
          ...{
            isLoading: true,
            status: 'idle' as typeof state['status'],
          },
        }))
      )
    ),
    catchError((err) => {
      return of(
        this.reduceForUpdate(
          setProp('statusIncidents', (state) => ({
            ...state,
            ...{
              isLoading: false,
              isSuccess: false,
              isError: true,
              status: 'done' as typeof state['status'],
              error: err,
            },
          }))
        )
      );
    })
  );

  return loadEventIncident
    .asObservable()
    .pipe(switchMap(() => merge(loadEventIncident$, subjectStream$)));
}
