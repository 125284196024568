import { inject, Injectable } from '@angular/core';
import { OnStoreInit, StoreService } from '@matchman/common-front';
import { select } from '@ngneat/elf';
import {
  getEntity,
  selectAllEntities,
  selectEntity,
} from '@ngneat/elf-entities';
import { BillingAccount } from '@matchman/database/entities';
import { map } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import {
  shareReplay,
  combineLatest,
  startWith,
  distinctUntilChanged,
  filter,
  switchMap,
} from 'rxjs';

import { BillingAccountListState, accountListEntitiesRef } from '../state';

import {
  AccountFormComponent,
  BillingAccountData,
} from '../../../components/account-form/account-form.component';
import { putMoneyEffect, saveAccountEffect } from '../effect';
import { SumData } from '../billing-account-list.component';

@Injectable()
export class StateService
  extends StoreService<BillingAccountListState>
  implements OnStoreInit
{
  protected activatedRoute = inject(ActivatedRoute);
  protected router = inject(Router);
  protected saveAccountEffect = inject(saveAccountEffect);
  protected putMoneyEffect = inject(putMoneyEffect);
  protected isLoadingAccount$ = this.pipe(
    select((state) => state.accountListStatus.isLoading),
    distinctUntilChanged(),
    shareReplay({ refCount: true })
  );

  protected accountList$ = this.pipe(
    selectAllEntities({ ref: accountListEntitiesRef }),
    shareReplay({ refCount: true }),
    startWith([] as BillingAccount[])
  );

  protected pageInfo$ = this.pipe(
    select((state) => state.pageInfo),
    shareReplay({ refCount: true })
  );

  protected showModal$ = this.router.events.pipe(
    startWith(true),
    map(() => this.activatedRoute.firstChild),
    map((firstChild) => (firstChild ? !!firstChild.outlet : false)),
    distinctUntilChanged(),
    shareReplay({ refCount: true })
  );

  protected modalTitle$ = this.showModal$.pipe(
    filter((showModal) => showModal),
    map(() =>
      parseInt(this.activatedRoute.firstChild.snapshot.params['id'], 10)
    ),
    switchMap((id) =>
      this.pipe(
        selectEntity(id, { ref: accountListEntitiesRef }),
        filter(() => !!this.activatedRoute.firstChild),
        map((account) => ({
          component: this.activatedRoute.firstChild.component,
          account,
        })),
        map(({ component, account }) => {
          const title: Array<string | number> = [];
          const id = this.activatedRoute.firstChild.snapshot.params['id'];
          const text = id ? 'Edit account' : 'New Acount';
          switch (component) {
            case AccountFormComponent:
              title.push(...[text, account ? `#${account.id}` : undefined]);
              break;
          }
          return title.filter((i) => !!i).join(' ');
        })
      )
    ),
    startWith(''),
    distinctUntilChanged(),
    shareReplay({ refCount: true })
  );

  protected isLoadingModal$ = this.pipe(
    select((state) => state.savingInModal),
    distinctUntilChanged(),
    shareReplay({ refCount: true })
  );

  protected isPuttingMoney$ = this.pipe(
    select((state) => state.puttingMoney),
    distinctUntilChanged(),
    shareReplay({ refCount: true })
  );

  protected listMockAccount$ = this.accountList$.pipe(
    map((r) => r.filter((i) => i.amountType === 'Mock')),
    map((r) =>
      r.map((i) => ({
        details: `${i.id}-${i.details}`,
        id: i.id,
      }))
    ),
    shareReplay({ refCount: true })
  );

  vm$ = combineLatest([
    this.accountList$,
    this.isLoadingAccount$,
    this.pageInfo$,
    this.showModal$,
    this.modalTitle$,
    this.isLoadingModal$,
    this.listMockAccount$,
    this.isPuttingMoney$,
  ]).pipe(
    map(
      ([
        accountList,
        isLoadingAccount,
        pageInfo,
        showModal,
        modalTitle,
        isLoadingModal,
        listMockAccount,
        isPuttingMoney,
      ]) => ({
        accountList,
        isLoadingAccount,
        pageInfo,
        showModal,
        modalTitle,
        isLoadingModal,
        listMockAccount,
        isPuttingMoney,
      })
    )
  );

  getAccount(id: number): BillingAccount | undefined {
    return this.query(getEntity(id, { ref: accountListEntitiesRef }));
  }

  onStoreInit(): void {}

  saveAccount(data: BillingAccountData) {
    this.saveAccountEffect.next(data);
  }

  putMoney(data: SumData) {
    this.putMoneyEffect.next(data);
  }
}
