import { Column, Entity, PrimaryColumn, UpdateDateColumn } from 'typeorm';
import { ResultStatusEnum } from '../types';

export type IResultType = ResultType;

@Entity('result_type')
export class ResultType {
  @PrimaryColumn()
  public id!: number;

  @Column()
  public name!: string;

  @Column({
    name: 'result_type',
    type: 'varchar',
  })
  public statusType!: ResultStatusEnum;

  @Column({
    name: 'created_at',
  })
  public createdAt!: Date;

  @UpdateDateColumn({
    name: 'updated_at',
  })
  public updatedAt!: Date;
}
