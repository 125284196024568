import { inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ObserveFunc } from '@matchman/common-front';
import { Events } from '@matchman/database/entities';
import { endWithAsyncPipe } from '@matchman/frontend-utils';
import { setProp } from '@ngneat/elf';
import { map, switchMap } from 'rxjs/operators';
import { JsonApiSdkService } from 'json-api-nestjs-sdk';
import { QueryField } from 'json-api-nestjs';
import { catchError, filter, of, startWith } from 'rxjs';

import { StateService } from '../../service/state.service';
import { LineUpState } from '../index';

export function loadEvent(this: StateService): ObserveFunc<LineUpState> {
  const activatedRoute = inject(ActivatedRoute);
  const jsonApiUtilsService = inject(JsonApiSdkService);

  const loadEvent = (id) =>
    jsonApiUtilsService
      .getOne(Events, id, {
        [QueryField.include]: [
          'awayTeam',
          'homeTeam',
          'status',
          'tournament',
          'tournamentStage',
        ],
      })
      .pipe(
        map((r) => {
          r.id = parseInt(`${r.id}`, 10);
          return r;
        }),
        map((r) => this.reduceForUpdate(setProp('event', r))),
        startWith(
          this.reduceForUpdate(
            setProp('statusEvents', (state) => ({
              ...state,
              ...{
                isLoading: true,
                status: 'idle' as typeof state['status'],
              },
            }))
          )
        ),
        endWithAsyncPipe(
          this.reduceForUpdate(
            setProp('statusEvents', (state) => ({
              ...state,
              ...{
                isLoading: false,
                isSuccess: true,
                status: 'done' as typeof state['status'],
              },
            }))
          )
        ),
        catchError((err) => {
          return of(
            this.reduceForUpdate(
              setProp('statusEvents', (state) => ({
                ...state,
                ...{
                  isLoading: false,
                  isSuccess: false,
                  isError: true,
                  status: 'done' as typeof state['status'],
                  error: err,
                },
              }))
            )
          );
        })
      );

  return activatedRoute.parent.paramMap.pipe(
    map((r) => r.get('id')),
    filter((id) => !!id),
    map((id) => parseInt(id, 10)),
    switchMap((id) => loadEvent(id))
  );
}
