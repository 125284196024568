import { importProvidersFrom } from '@angular/core';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { IconDefinition } from '@ant-design/icons-angular';
import {
  CaretDownFill,
  MoreOutline,
  ArrowLeftOutline,
  SettingOutline,
  HomeOutline,
} from '@ant-design/icons-angular/icons';

const icons: IconDefinition[] = [
  CaretDownFill,
  MoreOutline,
  ArrowLeftOutline,
  SettingOutline,
  HomeOutline,
];

export default importProvidersFrom(NzIconModule.forRoot(icons));
