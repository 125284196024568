import { ParamMap } from '@angular/router';
import {
  distinctUntilChanged,
  filter,
  map,
  OperatorFunction,
  pipe,
} from 'rxjs';
import { number } from 'zod';

export function getNumberFromParamsPipe<T extends ParamMap>(
  paramName: string,
  defaultValue?: string | undefined
): OperatorFunction<T, number> {
  return pipe(
    map((r) => r.get(paramName)),
    filter((string: string | null): string is string => {
      if (defaultValue) return true;
      return !!string;
    }),
    map((idString) => {
      if (defaultValue && !idString) {
        return defaultValue;
      }
      return idString;
    }),
    map((idString) => parseInt(idString, 10)),
    distinctUntilChanged()
  );
}
