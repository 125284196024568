import { InjectionToken, Provider } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { RPC_CONFIG } from './rpc-config.provider';
import { RpcResponse, RpcConfig } from '../types';
import { catchError, of } from 'rxjs';

export const httpRpcCall = new InjectionToken('Http call rpc');

export const httpCallProvider: Provider = {
  provide: httpRpcCall,
  useFactory: (httpClient: HttpClient, config: RpcConfig) => {
    const url = new URL(config.rpcPath, config.rpcHost).toString();
    return <T extends () => any>(data: any) => {
      return httpClient.post<RpcResponse<T> | RpcResponse<T>[]>(url, data).pipe(
        catchError((e) =>
          of({
            jsonrpc: '2.0',
            error: { code: 0, message: e.message },
          })
        )
      );
    };
  },
  deps: [HttpClient, RPC_CONFIG],
};
