<nz-page-header
  (nzBack)="onBack()"
  nzBackIcon="arrow-left"
  nzTitle="List of line up"
  *rxLet="vm$; let vm"
>
  <nz-page-header-subtitle *ngIf="vm.eventStatus.isSuccess">
    Event: <strong>{{ vm.event.homeTeam.name }}</strong> -
    <strong>{{ vm.event.awayTeam.name }}</strong>
  </nz-page-header-subtitle>
  <nz-page-header-content>
    <nz-spin
      nzTip="Loading line up..."
      [nzSpinning]="vm.lineUpStatus.isLoading || vm.eventStatus.isLoading"
    >
      <div nz-row *ngIf="vm.lineUpStatus.isSuccess && vm.eventStatus.isSuccess">
        <div nz-col nzSpan="11">
          <ng-container
            *ngTemplateOutlet="tableList; context: { $implicit: {teamName: vm.event.homeTeam.name, lineUp: vm.lineUpHome} }"
          ></ng-container>
        </div>
        <div nz-col nzSpan="11" nzOffset="2">
          <ng-container
            *ngTemplateOutlet="tableList; context: { $implicit: {teamName: vm.event.homeTeam.name, lineUp: vm.lineUpAway} }"
          ></ng-container>
        </div>
      </div>
    </nz-spin>
  </nz-page-header-content>
</nz-page-header>
<ng-template #tableList let-vm>
  <ng-template #teamNameTitle>
              <span
              >Line up for:
                <strong>{{ vm.teamName }}</strong></span
              >
  </ng-template>
  <nz-table
    [nzTitle]="teamNameTitle"
    [nzData]="vm.lineUp"
    [nzShowPagination]="false"
    [nzLoading]="vm.isLoading"
    nzSize="small"
    [nzBordered]="true"
    appResizeTable
  >
    <thead>
    <tr>
      <th nzWidth="50px">#</th>
      <th>Player name</th>
      <th nzWidth="150px">Position</th>
      <th nzWidth="60px">Incident</th>
      <th nzWidth="50px">Point</th>
      <th nzWidth="100px"></th>
    </tr>
    </thead>
    <tbody>
    <tr
      *ngFor="
        let item of vm.lineUp;
        let i = index;
        trackBy: trackBy
      "
    >
      <td>{{ i + 1 }}</td>
      <td>
        {{ item.players.name }} ({{ item.players.positionName }})
      </td>
      <td>{{ item.positionName }}</td>
      <td>{{ item.incidentPoint.length }}</td>
      <td>{{ item.point }}</td>
      <td>
        <a [routerLink]="['./', { outlets: { 'modal': ['incident-player', item.id] }}]">Point detail</a>
      </td>
    </tr>
    </tbody>
  </nz-table>
</ng-template>
<router-outlet name="modal"></router-outlet>
