import { inject, InjectionToken, Provider } from '@angular/core';
import { httpRpcCall } from './http-call.provider';
import { WrapperCall } from '../utils';
import { RpcHttpCall, RpcReturnList } from '../types';

export const RPC = new InjectionToken('rpc');

export const rpcProvider: Provider = {
  provide: RPC,
  useFactory: <T extends RpcReturnList<any>>() => {
    const httpRpc = inject<RpcHttpCall<() => any>>(httpRpcCall);
    const mockRpcNameSpace = {} as T;
    return new Proxy<T>(mockRpcNameSpace, {
      get(target, nameSpace: keyof T) {
        const mockRpcmethode = {} as T[typeof nameSpace];
        return new Proxy<T[typeof nameSpace]>(mockRpcmethode, {
          get(target, method: keyof T[typeof nameSpace], ...arg: any[]) {
            const call = new WrapperCall(String(nameSpace), String(method));
            return call.callRpc.bind(call, httpRpc);
          },
        });
      },
    });
  },
};
